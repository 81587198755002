import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { publicRequest } from "../../utils/requestMethod";
import { clearCart } from "../../utils/redux/cartRedux";

const Paypal = ({ amount }) => {
  const paypal = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, error) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "V&C Delivery",
                amount: {
                  currency_code: "EUR",
                  value: amount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();

          const amnt = parseFloat(order.purchase_units[0].amount.value);

          // const res = await publicRequest.post(`/paypal/checkout/payment`, {
          //   amount: amount,
          // });
          // console.log(res);
          // Capture Transaction ID/ order.id
          // let paypalData = {};

          navigate("/success", {
            state: {
              paymentData: order,
              products: cart,
              amount: amnt,
              paymentType: "Paypal",
            },
          });
          dispatch(clearCart({ cart }));
        },
        onError: (error) => {
          console.log(error);
        },
        style: {
          shape: "pill",
        },
      })
      .render(paypal.current);
  }, [amount, cart, dispatch, navigate]);
  return (
    <div>
      <div ref={paypal}> </div>
    </div>
  );
};

export default Paypal;
