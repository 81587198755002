import React from "react";
import styled from "styled-components";
import FoodOrderCardItem from "./FoodOrderCardItem";
import { RestaurantData } from "../../dummydata/data";
import { mobile, tablet } from "../../responsive";
import { useState } from "react";
import { useEffect } from "react";
import { publicRequest } from "../../utils/requestMethod";

const Container = styled.div`
  /* height: 70vh; */

  padding-top: 5px;
  margin-left: 50px;
  margin-right: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mobile({
    width: "100vw",
    margin: "0px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({ padding: "0px", paddingBottom: "50px" })}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 100%;

  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
  })}
  ${tablet({ padding: "0px", width: "100%" })}
`;

const FoodOrderCard = () => {
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    const getRestaurants = async () => {
      const res = await publicRequest.get("/restaurant");
      setRestaurants(res.data);
    };
    getRestaurants();
  }, []);

  return (
    <Container>
      <Wrapper>
        {restaurants.map((item) => (
          <FoodOrderCardItem item={item} key={item._id} />
        ))}
      </Wrapper>
    </Container>
  );
};

export default FoodOrderCard;
