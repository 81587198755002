import styled from "styled-components";
// import "./css/team.css";
import { mobile, tablet } from "../../responsive";
import "../style/team.css";

const Container = styled.div`
  height: 50vh;

  display: flex;
  flex-direction: column;
  width: 450px;
  color: #002035;
  margin-top: 20px;
  padding-top: 20px;
  position: relative;
  margin-bottom: 80px;
  margin-left: 30px;
  &:hover .image {
    opacity: 0.4;
  }
  ${mobile({
    height: "50vh",
    width: "100%",
    marginBottom: "60px",
    marginLeft: "0px",
    display: "flex",
    flexDirection: "column",
  })}
  ${tablet({
    height: "40vh",
    width: "100%",
    marginBottom: "60px",
    marginLeft: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  })}
`;
const ImageContainer = styled.div`
  height: 80%;
  width: 100%;
  /* display: none; */
  ${tablet({
    height: "80%",
    width: "80%",
  })}
  ${mobile({
    height: "70vh",
    width: "100%",
    marginLeft: "0px",
  })}
`;

const Image = styled.img`
  object-fit: cover;
  opacity: 1;
  transition: calc() 0.5s ease;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
`;

const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  ${mobile({ display: "none" })}
  ${tablet({ display: "none" })}
`;
const Rank = styled.h1`
  color: #002035;
`;
const Name = styled.h2`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: 400;
`;

const MobileRank = styled.h3`
  display: none;
  ${mobile({ display: "block", textAlign: "center" })}
  ${tablet({ display: "block", textAlign: "center" })}
`;

const TeamCard = ({ team }) => {
  return (
    <Container className="container animate__animated animate__fadeIn animate__slower">
      <MobileRank>{team.rank} </MobileRank>
      <ImageContainer>
        <Image className="image" src={team.img} />
      </ImageContainer>
      <Middle className="middle">
        <Rank>{team.rank} </Rank>
      </Middle>
      <Name>{team.name}</Name>
    </Container>
  );
};

export default TeamCard;
