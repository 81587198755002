import styled from "styled-components";
import FoodOrderCard from "../../../components/foodorder/FoodOrderCard";
import Navbar from "./../../../components/navbar/Navbar";
const Container = styled.div`
  background-color: #deba59;
  padding-bottom: 20px;
`;

const NFO = () => {
  return (
    <>
      <Navbar />
      <Container>
        <FoodOrderCard />
      </Container>
    </>
  );
};

export default NFO;
