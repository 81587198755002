import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../../../responsive";
import { publicRequest } from "./../../../utils/requestMethod";
import Navbar from "../../../components/navbar/Navbar";

const Container = styled.div`
  width: 100%;
  background-color: #deba59;
  color: #002035;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
`;
const Wrapper = styled.div`
  width: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobile({
    padding: "0px",
    width: "95%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "90%",
    alignItems: "stretch",
  })}
`;

const Title = styled.h1`
  padding: 30px;
  text-align: center;
`;
const OrderBody = styled.div`
  display: flex;
  flex-direction: column;
`;
const OrderTitle = styled.h2`
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
`;

const OrderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const OrderDetailsLeft = styled.div`
  font-size: 2.5vh;
  ${mobile({
    fontSize: "2vh",
  })}
`;
const OrderDetailsRight = styled.div`
  font-size: 2.5vh;
  ${mobile({
    fontSize: "2vh",
  })}
`;

const Button = styled.button`
  width: 80%;
  height: 5vh;
  border-radius: 2.5vh;
  border: none;
  background-color: #002035;
  color: #deba59;
  font-size: 2.5vh;
  ${mobile({
    width: "100%",
  })}
`;

const ReservationSucess = () => {
  const [restaurant, setRestaurant] = useState({});
  const location = useLocation();
  const reservationOrder = location.state.reservationData;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const getRestaurant = async () => {
      const res = await publicRequest.get(
        `/restaurant/find/${reservationOrder.restaurant}`
      );
      setRestaurant(res.data);
    };
    getRestaurant();
  }, [reservationOrder.restaurant]);

  const returnHome = (e) => {
    e.preventDefault();
    navigate("/");
  };

  // useEffect(() => {
  //   const makeReservation = async () => {
  //     try {
  //       // const reservation = {
  //       //   email: user.email,
  //       //   owner: user._id,
  //       //   description: reservationOrder.description,
  //       //   pers: reservationOrder.pers,
  //       //   restaurant: reservationOrder.restaurant,
  //       //   date: reservationOrder.date,
  //       //   time: reservationOrder.time,
  //       // };
  //       // const res = await publicRequest.post("/reservation", reservation);
  //       // console.log(res.data);
  //     } catch (error) {}
  //   };
  //   makeReservation();
  //   const timeout = setTimeout(() => {
  //     window.location.replace("/");
  //   }, 3000);
  //   return () => clearTimeout(timeout);
  // }, [reservationOrder, user._id, user.email]);

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>Reservierungsauftrag ausgeführt</Title>
          <OrderBody>
            <OrderTitle>
              Nachfolgend finden Sie die Details Ihres Reservierungsauftrags.
            </OrderTitle>
            <OrderDetails>
              <OrderDetailsLeft>Auftragsnummer:</OrderDetailsLeft>
              <OrderDetailsRight> {reservationOrder._id} </OrderDetailsRight>
            </OrderDetails>
            <OrderDetails>
              <OrderDetailsLeft>Restaurant</OrderDetailsLeft>
              <OrderDetailsRight>{restaurant.name}</OrderDetailsRight>
            </OrderDetails>
            <OrderDetails>
              <OrderDetailsLeft>Status:</OrderDetailsLeft>
              <OrderDetailsRight>{reservationOrder.status}</OrderDetailsRight>
            </OrderDetails>
            <OrderDetails>
              <OrderDetailsLeft>Personenzahl</OrderDetailsLeft>
              <OrderDetailsRight>{reservationOrder.pers}</OrderDetailsRight>
            </OrderDetails>
            <OrderDetails>
              <OrderDetailsLeft>Datum:</OrderDetailsLeft>
              <OrderDetailsRight>{reservationOrder.date}</OrderDetailsRight>
            </OrderDetails>
            <OrderDetails>
              <OrderDetailsLeft>Zeit:</OrderDetailsLeft>
              <OrderDetailsRight>{reservationOrder.time}</OrderDetailsRight>
            </OrderDetails>
          </OrderBody>
        </Wrapper>
      </Container>
    </>
  );
};

export default ReservationSucess;
