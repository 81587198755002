import React from "react";
import styled from "styled-components";
import Navbar from "../components/navbar/Navbar";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  padding-bottom: 30px;
`;

const Wrapper = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({ height: "60vh" })}
`;

const Title = styled.h1`
  padding: 50px;
  font-weight: bolder;
  font-size: 70px;
  ${mobile({ fontSize: "10vw" })}
`;
const Body = styled.h2`
  font-weight: 400;
  font-size: 50px;
  ${mobile({ fontSize: "8vw", width: "40vh" })}
  ${tablet({ fontSize: "5vw", width: "40vh" })}
`;

const ErrorPage = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title className="animate__animated animate__pulse animate__infinite">
            Page Not Found: Error 404
          </Title>
          <Body>You must have taken a Wrong Turn</Body>
        </Wrapper>
      </Container>
    </>
  );
};

export default ErrorPage;
