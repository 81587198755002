import styled from "styled-components";
import Pastry from "./Pastry";
import { PastryData } from "../../../dummydata/data";
import { mobile, tablet } from "../../../responsive";
import { useState, useEffect } from "react";
import { publicRequest } from "./../../../utils/requestMethod";
import Pastry2 from "./Pastry2";
import { pastryType } from "../../../utils/customHooks/types";

const Container = styled.div`
  padding-top: 5px;
  margin-left: 50px;
  background-color: #deba59;
  margin-right: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${mobile({
    width: "100vw",
    marginLeft: "0px",
    marginRight: "0px",
    paddingTop: "5px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({
    padding: "0px",
    marginLeft: "0px",
    marginRight: "0px",
  })}
`;

const Title = styled.h1`
  color: #002035;
  text-align: center;
  font-weight: 400;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "100%",
    margin: "0px",
  })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  ${mobile({ margin: "10px 0px" })}
  border-radius: 10px;
`;
const Option = styled.option`
  padding: 10px;
  margin: 10px;
`;

const DiscountedPastries = () => {
  const [pastrys, setPastrys] = useState([]);
  const [type, setType] = useState("");
  // const [discounted, setDiscounted] = useState(null);
  // const [disPas, setDisPas] = useState([]);

  useEffect(() => {
    const getPastrys = async () => {
      try {
        const res = await publicRequest.get(
          type ? `/pastry?type=${type}` : `/pastry`
        );
        const dis = res.data.filter((d) => {
          return d.discountStatus === true;
        });
        setPastrys(dis);
      } catch (error) {}
    };
    getPastrys();
  }, [type]);

  return (
    <Container>
      <Title>ermäßigtes Gebäck/Pudding</Title>
      {/* <Select onChange={(e) => setDiscounted(e.target.value)}>
        <Option>Pick Discount Option</Option>
        <Option value="true">True</Option>
        <Option value="false">False</Option>
      </Select> */}
      <Select onChange={(e) => setType(e.target.value)}>
        <Option>Select a Pastry Type</Option>

        {pastryType.map((pt) => (
          <Option key={pt.id} value={pt.name}>
            {pt.name}
          </Option>
        ))}
      </Select>
      <Wrapper>
        {pastrys.map((item) => (
          <Pastry2 item={item} key={item._id} />
        ))}
      </Wrapper>
    </Container>
  );
};

export default DiscountedPastries;
