import styled from "styled-components";
import Navbar from "../../../components/navbar/Navbar";
import FoodMenu from "./FoodMenu";

const Container = styled.div`
  background-color: #deba59;
  /* padding-bottom: 20px; */
`;

const FoodMenuList = () => {
  return (
    <>
      <Navbar />
      <Container>
        <FoodMenu />
      </Container>
    </>
  );
};

export default FoodMenuList;
