import { useSelector } from "react-redux";

const useGetTotalCartCost = () => {
  const cart = useSelector((state) => state.cart);
  let totalPastryCartCost = 0;
  let totalProductCartCost = 0;
  let totalLaundryCartCost = 0;
  let totalFoodordersCartCost = 0;
  let totalFooddeliveryCartCost = 0;
  let totalGroceryCartCost = 0;
  let totalGroceryProductCartCost = 0;
  let totalCartCost = 0;

  let foodService = 5;

  cart.pastryorders.map((p) => {
    return (totalPastryCartCost += p.quantity * (p.price - p.discount));
  });
  cart.laundryorders.map((l) => {
    return (totalLaundryCartCost += l.quantity * l.price);
  });
  cart.productorders.map((pr) => {
    return (totalProductCartCost += pr.quantity * pr.price);
  });
  cart.foodorders.map((fo) => {
    return (totalFoodordersCartCost += fo.quantity * fo.price);
  });

  cart.fooddeliverys.map((fo) => {
    return (totalFooddeliveryCartCost += fo.quantity * fo.price);
  });

  if (totalFooddeliveryCartCost === 0) {
    totalFooddeliveryCartCost = 0;
  } else {
    totalFooddeliveryCartCost = foodService + totalFooddeliveryCartCost;
  }

  cart.groceryorders.map((g) => {
    // return (totalGroceryCartCost += g.quantity * g.price);
    return (totalGroceryCartCost = 30 + cart.groceryorders.length * 0.25);
  });

  cart.groceryproducts.map((p) => {
    return (totalGroceryProductCartCost += p.quantity * p.price);
  });

  totalCartCost =
    totalFooddeliveryCartCost +
    totalFoodordersCartCost +
    totalProductCartCost +
    totalGroceryCartCost +
    totalLaundryCartCost +
    totalPastryCartCost +
    totalGroceryProductCartCost;

  return { totalCartCost };
};

export default useGetTotalCartCost;
