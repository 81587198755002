import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";

const Container = styled.div`
  width: 25vw;
  height: 30vh;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 20px;
  margin-top: 20px;

  ${mobile({
    width: "100%",
    marginTop: "10px",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "20px",
    height: "40vh",
  })}
  ${tablet({
    width: "45%",
    marginLeft: "12.5px",
    marginRight: "12.5px",
    marginBottom: "5px",
  })}
`;

const Wrapper = styled.div`
  color: #002035;
  width: 100%;
  height: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding-bottom: 30px;
  ${mobile({ width: "100%", paddingBottom: "20px" })}
  ${tablet({ width: "100%", paddingBottom: "0px" })}
`;

const InnerWrapper = styled.div`
  width: 90%;
  height: 100%;
  margin-top: 10px;
`;

const ImageContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 75%;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
`;
const Info = styled.div`
  /* top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Desc = styled.p`
  text-align: center;
`;

const FoodOrderCardItem = ({ item }) => {
  return (
    <Container>
      <Link to={`/essen-bestellen/${item._id}`}>
        <Wrapper>
          <InnerWrapper>
            <ImageContainer>
              <Image src={item.image} />
            </ImageContainer>
            <Info>
              <Desc>{item.desc}</Desc>
            </Info>
            <Title>{item.name} </Title>
          </InnerWrapper>
        </Wrapper>
      </Link>
    </Container>
  );
};

export default FoodOrderCardItem;
