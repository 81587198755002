import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.errorMessage = "";
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
      state.errorMessage = "";
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    logoutUser: (state) => {
      state.currentUser = null;
    },
    refreshToken: (state, action) => {
      state.currentUser.accessToken = action.payload.accessToken;
      state.currentUser.refreshToken = action.payload.refreshToken;
    },
  },
}); //End of Slice

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutUser,
  refreshToken,
} = userSlice.actions;
export default userSlice.reducer;
