import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import useGetTotalCartCost from "../../utils/customHooks/useGetTotalCartCost";
import { updateShippingInfo } from "../../utils/redux/cartRedux";
import { MenuItem, Select, TextField } from "@mui/material";

import deliveryZoneCost from "../../utils/customHooks/deliveryZones";

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 100vh;
  background-color: #deba59 !important;
  color: #002035 !important;
  display: flex;

  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({
    padding: "0px",
    width: "95%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "70%",
    margin: "0px",
  })}
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  ${mobile({
    margin: "5px",
  })}
`;
const Label = styled.label`
  flex: 1;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 300;
`;
const Input = styled.input`
  flex: 1;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;

// const Select = styled.select`
//   padding: 5px;
//   border-radius: 5px;
//   font-size: 15px;
// `;
// const Option = styled.option`
//   padding: 5px;
//   border-radius: 5px;
//   font-size: 15px;
// `;

const TextArea = styled.textarea`
  border-radius: 5px;
`;
const Button = styled.button`
  width: 100%;
  border: none;
  height: 40px;
  font-size: 20px;
  color: #deba59;
  background-color: #002035;
  border-radius: 20px;
  margin-top: 20px;
  cursor: pointer;
`;

const Checkout = () => {
  const { totalCartCost } = useGetTotalCartCost();
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [person, setPerson] = useState("");
  const [phone, setPhone] = useState("");
  const [extra, setExtra] = useState("");
  const [dest, setDest] = useState("");
  const [shippingCost, setShippingCost] = useState("");

  const navigate = useNavigate();

  const proceedToReview = () => {
    dispatch(
      updateShippingInfo({ shippingCost, dest, address, person, phone, extra })
    );

    navigate("/review");
  };

  return (
    <Container>
      <Wrapper>
        <Title>V&C Delivery</Title>
        <SubTitle>Enter Delivery Details</SubTitle>
        <Form onSubmit={proceedToReview}>
          <InputDiv>
            <Label>Name of Recipient</Label>
            <Input
              value={person}
              required
              placeholder="Jonathan Moss"
              onChange={({ target }) => setPerson(target.value)}
            />
          </InputDiv>
          <InputDiv>
            <Label>Delivery Address</Label>
            <Input
              value={address}
              required
              placeholder="Somewhere in Stockholm"
              onChange={({ target }) => setAddress(target.value)}
            />
          </InputDiv>
          <InputDiv>
            <Label>Select a Zone</Label>
            {/* <Select
              onChange={({ target }) => {
                setDest(target.value?.zone);
                setShippingCost(target.value?.price);
              }}
            >
              <Option disabled>Pick a Zone</Option>
              {deliveryZoneCost.map((dzc) => (
                <Option
                  value={dzc}
                  selected={dzc?.price === dest?.price}
                  key={dzc.id}
                >
                  {dzc.area}
                </Option>
              ))}
            </Select> */}
            <Select
              sx={{ m: 1 }}
              placeholder="Zone"
              onChange={({ target }) => {
                setDest(target.value?.area);
                setShippingCost(target.value?.price);
              }}
              required
            >
              {deliveryZoneCost.map((item) => (
                <MenuItem
                  selected={item?.price === dest?.price}
                  key={item.id}
                  value={item}
                >
                  {item.area}
                </MenuItem>
              ))}
            </Select>
          </InputDiv>
          <InputDiv>
            <Label>Mobile Number of Recipient</Label>
            <Input
              placeholder="+44556321456"
              required
              onChange={({ target }) => setPhone(target.value)}
              value={phone}
            />
          </InputDiv>
          <InputDiv>
            <Label>Additional Details</Label>
            <TextArea
              rows="2"
              onChange={({ target }) => setExtra(target.value)}
              value={extra}
            ></TextArea>
          </InputDiv>

          <Button type="submit">PROCEED TO PAYMENT</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Checkout;
