import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { login } from "../../utils/redux/apicalls";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #deba59 !important;
  color: #002035 !important;
  /* background: linear-gradient(rgba(95, 122, 25, 0.5), rgba(128, 226, 78, 0.5)),
    url("https://images.unsplash.com/photo-1545873509-33e944ca7655?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80")
      center;
  background-size: cover; */
`;
const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({
    padding: "0px",
    width: "90%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "70%",
    margin: "0px",
  })}
`;
const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
`;
const Label = styled.label`
  flex: 1;
  font-size: 25px;
  font-weight: 300;
  ${mobile({
    padding: "0px",
    fontWeight: "400",
  })}
`;
const Input = styled.input`
  flex: 1;
  padding: 15px;
  border-radius: 5px;
  font-size: 20px;
`;

const Error = styled.span`
  color: red;
  font-size: 3.5vh;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  font-size: 20px;
  color: #deba59;
  background-color: #002035;
  border-radius: 30px;
  margin-top: 20px;
`;

const Span = styled.button`
  width: 100%;
  /* border: none; */
  border: 1px solid #002035;
  padding: 15px 20px;
  font-size: 20px;
  background-color: white;
  color: #002035;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Span2 = styled.p`
  width: 100%;
  /* border: none; */
  border: none;
  font-size: 15px;
  color: #002035;
  margin-top: 10px;
  cursor: pointer;
  text-align: right;
`;

const Login = () => {
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const error = useSelector((state) => state.user.errorMessage);
  const isFetching = useSelector((state) => state.user.isFetching);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const handleLogin = (e) => {
    e.preventDefault();
    login(dispatch, { email, password });
    setErrorMessage(error);
  };

  return (
    <Container>
      <Wrapper>
        <Title>V&C DELIVERY.DE</Title>
        <SubTitle>Anmeldung</SubTitle>
        {errorMessage && (
          <Stack sx={{ margin: "10px" }} spacing={2}>
            <Alert
              onClose={() => {
                setErrorMessage("");
              }}
              severity="error"
            >
              {errorMessage}
            </Alert>
          </Stack>
        )}

        <Form>
          <InputDiv>
            <Label>Email</Label>
            <Input
              placeholder="jm@email.com"
              required
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
            />
          </InputDiv>
          <InputDiv>
            <Label>Passwort</Label>
            <Input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputDiv>
          <Button
            disabled={isFetching}
            style={isFetching ? { backgroundColor: "grey" } : null}
            onClick={handleLogin}
          >
            Anmeldung
          </Button>
        </Form>
        <Link to="/register">
          <Span>Konto anlegen</Span>
        </Link>
        <Link to="/forgotpassword">
          <Span2>Passwort vergessen?</Span2>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Login;
