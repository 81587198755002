import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { userRequest, useUserRequest } from "../../utils/requestMethod";
import { mobile } from "../../responsive";
import { publicRequest } from "./../../utils/requestMethod";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 100px;
  ${mobile({ flexDirection: "column" })}
`;
const WrapperLeft = styled.div`
  flex: 1;
  margin-left: 20px;
`;
const WrapperLeftTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperLeftBody = styled.div``;

const Title = styled.h2``;

const WrapperBodyContainer = styled.div`
  display: flex;
  margin: 15px;
`;
const WrapperInfoKey = styled.div`
  flex: 1;
`;
const WrapperInfoValue = styled.div`
  flex: 1;
`;

const WrapperRight = styled.div`
  flex: 1;
`;
const WrapperRightTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperFormContainer = styled.div`
  background-color: white;
  justify-content: center;
  align-items: center;
  width: 90%;
  ${mobile({ width: "100%" })}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
`;

const Label = styled.label`
  padding: 5px;
`;
const Input = styled.input`
  padding: 10px;
  margin: 5px;
`;
const TextArea = styled.textarea``;

const Select = styled.select`
  padding: 5px;
  height: 40px;
`;
const Option = styled.option`
  background-color: white;
  color: teal;
  font-size: 17px;
`;

const DeleteButton = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: red;
  color: white;
  cursor: pointer;
  border: 1px solid teal;
  border-radius: 5px;
  margin-top: 20px;
  width: 50%;
  align-self: center;
  :hover {
    background-color: white;
    color: red;
  }
`;

const SubmitButton = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid teal;
  border-radius: 5px;
  margin-top: 20px;
  width: 50%;
  align-self: center;
  :hover {
    background-color: teal;
    color: white;
  }
`;

const P = styled.p``;

const Profile = () => {
  // const [User, setUser] = useState({});
  const [inputs, setInputs] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  // let location = useLocation();
  console.log(user._id);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const getUser = async () => {
  //     const res = await publicRequest.get(`/users/find/${user._id}`);
  //     setUser(res.data);
  //     console.log(res.data);
  //   };
  //   getUser();
  // }, [user._id]);

  const handleChange = (e) => {
    e.preventDefault();
    setInputs((prev) => {
      return { ...prev, [e?.target?.name]: e?.target?.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInput = {
      ...inputs,
    };
    try {
      console.log(userInput);
      const res = await publicRequest.put(`/users/${user._id}`, userInput);
      console.log(res.data);
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      await publicRequest.delete(`/users/${user._id}`);
      localStorage.removeItem("persist:vcd");
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <WrapperLeft>
            <WrapperLeftTop>
              <Title>User Information</Title>
            </WrapperLeftTop>
            <WrapperLeftBody>
              <WrapperBodyContainer>
                <WrapperInfoKey>First Name: </WrapperInfoKey>
                <WrapperInfoValue>{user.firstname} </WrapperInfoValue>
              </WrapperBodyContainer>
              <WrapperBodyContainer>
                <WrapperInfoKey>Last Name: </WrapperInfoKey>
                <WrapperInfoValue>{user.lastname} </WrapperInfoValue>
              </WrapperBodyContainer>
              <WrapperBodyContainer>
                <WrapperInfoKey>Email: </WrapperInfoKey>
                <WrapperInfoValue>{user.email}</WrapperInfoValue>
              </WrapperBodyContainer>
              <DeleteButton onClick={handleDelete}>
                Delete My Account
              </DeleteButton>
            </WrapperLeftBody>
          </WrapperLeft>
          <WrapperRight>
            <WrapperRightTop>
              <Title>Edit User Information</Title>
            </WrapperRightTop>
            <WrapperFormContainer>
              <Form>
                <Label>First Name</Label>
                <Input
                  name="firstname"
                  placeholder="Segun Arinze Musa"
                  defaultValue={user.firstname}
                  onChange={handleChange}
                />
                <Label>Last Name</Label>
                <Input
                  name="lastname"
                  placeholder="Segun Arinze Musa"
                  defaultValue={user.lastname}
                  onChange={handleChange}
                />
                <Label>Email</Label>
                <Input
                  name="email"
                  placeholder="Email"
                  defaultValue={user.email}
                  onChange={handleChange}
                />

                {/* <Label>Profile Picture</Label>
              <Input
                name="profilePicture"
                defaultValue={user.profilePicture}
                onChange={handleChange}
              /> */}
                {/* <Label>Password</Label>
              <Input name="password" onChange={handleChange} /> */}
                <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
              </Form>
              <P>
                After Changes are made, log out and then log back in to see the
                changes
              </P>
            </WrapperFormContainer>
          </WrapperRight>
        </Wrapper>
      </Container>
    </>
  );
};

export default Profile;
