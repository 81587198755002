import React from "react";
import styled from "styled-components";
import Product from "./Product";
import { mobile, tablet } from "../../../responsive";
import { useState } from "react";
import { useEffect } from "react";
import { publicRequest } from "./../../../utils/requestMethod";
import { productType } from "../../../utils/customHooks/types";
import Navbar from "../../../components/navbar/Navbar";

const Container = styled.div`
  /* height: 70vh; */
  padding-top: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
  background-color: #deba59;
  flex-direction: column;
  ${mobile({
    width: "100vw",
    margin: "0px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({ padding: "0px", paddingBottom: "50px" })}
`;

const Title = styled.h2`
  color: #002035;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 100%;

  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
  })}
  ${tablet({ padding: "0px", width: "100%" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  ${mobile({ margin: "10px 0px" })}
  border-radius: 10px;
`;
const Option = styled.option`
  padding: 10px;
  margin: 10px;
`;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [type, setType] = useState("");

  useEffect(() => {
    const getCloths = async () => {
      const res = await publicRequest.get(
        type ? `/product?type=${type}` : `/product`
      );
      setProducts(res.data);
    };
    getCloths();
  }, [type]);
  document.title = "Frische Lebensmittel bequem nach Hause geliefert";

  document
    .querySelector("meta[name='description']")
    .setAttribute(
      "content",
      "Bestellen Sie frische Lebensmittel online bei v&cdelivery und lassen Sie sie bequem nach Hause liefern. Wählen Sie aus einer Vielzahl von Produkten und Lieferoptionen."
    );

  return (
    <>
      <Navbar />
      <Container>
        <Title>rabatt einkaufen</Title>
        {/* <Select onChange={(e) => setType(e.target.value)}>
        <Option disabled selected>
          Select a Product Type
        </Option>

        {productType.map((pt) => (
          <Option key={pt.id} value={pt.name}>
            {pt.name}
          </Option>
        ))}
      </Select> */}
        <Wrapper>
          {products.map((item) => (
            <Product key={item._id} item={item} />
          ))}
        </Wrapper>
      </Container>
    </>
  );
};

export default Products;
