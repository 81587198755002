import React from "react";
import styled from "styled-components";
import HomecardItem from "./HomecardItem";
import { HomeData } from "../../dummydata/data";
import { mobile, tablet } from "../../responsive";
import Announcement from "../announcement/Announcement";

const Container = styled.div`
  /* height: 70vh; */

  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  ${mobile({
    width: "100vw",
    margin: "0px",
    paddingTop: "5px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({ padding: "0px" })}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
  })}
  ${tablet({ padding: "0px", width: "100%" })}
`;

const Homecard = () => {
  return (
    <Container>
      <Wrapper>
        {HomeData.map((item) => (
          <HomecardItem item={item} key={item.id} />
        ))}
      </Wrapper>
    </Container>
  );
};

export default Homecard;
