import React from "react";
import styled from "styled-components";
import Burger from "./Burger";
import { Link } from "react-router-dom";
const Nav = styled.nav`
  width: 100vw;
  height: 55px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    padding: 10px;
    font-size: 3vh;
    margin-left: 10px;
  }
`;

const Navbar = () => {
  return (
    <Nav>
      <Link to="/">
        <div className="logo">V&C Delivery</div>
      </Link>
      <Burger />
    </Nav>
  );
};

export default Navbar;
