import React from "react";
import styled from "styled-components";
import "animate.css";
import { mobile, tablet } from "../../responsive";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: black;
  /* background-image: url("https://images.pexels.com/photos/2885320/pexels-photo-2885320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"); */
`;
const Wrapper = styled.div`
  margin-top: 50px;
  padding-top: 25px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 20px;
  background-color: #deba59;
  ${mobile({ marginLeft: "5px", marginRight: "5px" })}
  ${tablet({ marginLeft: "5px", marginRight: "5px" })}
`;

const WrapperTop = styled.div`
  display: flex;
  margin-bottom: 20px;
  ${mobile({ flexDirection: "column", fontSize: "15px" })}
  ${tablet({ flexDirection: "column", fontSize: "15px" })}
`;
const WrapperBottom = styled.div``;
const Left = styled.div`
  flex: 1;
  margin-right: 50px;
  display: none;
  ${mobile({ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" })}
  ${tablet({ marginLeft: "50px", marginBottom: "10px" })}
`;

const LeftTitle = styled.h1`
  text-align: center;
  font-weight: bolder;
`;

const LeftBody = styled.div`
  font-size: 25px;
  text-align: justify;
`;

const Center = styled.div`
  flex: 1;
  margin-left: 50px;
  margin-right: 50px;
  ${mobile({ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" })}
  ${tablet({ marginBottom: "10px" })}
`;

const CenterTitle = styled.h1`
  text-align: center;
  font-weight: bolder;
`;

const CenterBody = styled.div`
  font-size: 25px;
  text-align: justify;
`;

const Right = styled.div`
  flex: 1;
  margin-left: 50px;
  display: none;
  ${mobile({ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" })}
  ${tablet({ marginRight: "50px", marginBottom: "10px" })}
`;

const RightTitle = styled.h1`
  text-align: center;
  font-weight: bolder;
`;
const RightBody = styled.div`
  font-size: 25px;
  text-align: justify;
`;

const Bottom = styled.div`
  display: none;
`;
const BottomTitle = styled.h1`
  text-align: center;
  font-weight: bolder;
`;
const BottomBody = styled.div`
  font-size: 25px;
  text-align: justify;
  margin-left: 250px;
  margin-right: 250px;
  ${mobile({ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" })}
  ${tablet({ marginLeft: "50px", marginBottom: "10px", marginRight: "50px" })}
`;
const AboutUs = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <WrapperTop>
            <Left>
              <LeftTitle className="animate__animated animate__fadeInUp">
                Core Values
              </LeftTitle>
              <LeftBody>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
                esse nam laboriosam recusandae aspernatur rem magni magnam quos
                sit nulla sed quis perferendis, impedit commodi architecto
                nesciunt perspiciatis blanditiis eaque?
              </LeftBody>
            </Left>
            <Center>
              <CenterTitle className="animate__animated animate__fadeInUp">
                Über uns
              </CenterTitle>
              <CenterBody>
                V&C Delivery ermöglicht eine einfache, schnellere und
                zuverlässige Lieferung für Unternehmen und Verbraucher. Es ist
                eine gute Wahl für Restaurants, Einzelhändler, individuelle
                Lebensmitteleinkäufe, Brotbestellungen und mehr.
              </CenterBody>
            </Center>
            <Right>
              <RightTitle className="animate__animated animate__fadeInUp">
                Vision for the Future
              </RightTitle>
              <RightBody>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Et
                iste rerum excepturi labore nam minima expedita provident maxime
                neque! Harum dicta vitae voluptates provident accusamus officiis
                ex ad maiores quaerat!
              </RightBody>
            </Right>
          </WrapperTop>
          <WrapperBottom>
            <Bottom>
              <BottomTitle className="animate__animated animate__fadeInUp">
                About V&C Delivery
              </BottomTitle>
              <BottomBody>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Obcaecati totam expedita illum cum inventore praesentium, aut
                earum laborum voluptatibus accusantium, non quis rem impedit.
                Facere quisquam cupiditate magnam cum quam! Lorem ipsum dolor
                sit amet consectetur, adipisicing elit. Repudiandae pariatur
                molestiae vel sapiente, iure saepe libero repellendus voluptas!
                Fugiat, voluptatum! Sequi quis provident eligendi. Velit veniam
                et iure ratione tempora?
              </BottomBody>
            </Bottom>
          </WrapperBottom>
        </Wrapper>
      </Container>
    </>
  );
};

export default AboutUs;
