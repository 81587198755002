import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { mobile, tablet } from "../../../responsive";
import { publicRequest, useUserRequest } from "../../../utils/requestMethod";
import { useNavigate } from "react-router";
import Navbar from "../../../components/navbar/Navbar";

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #deba59 !important;
  color: #002035 !important;
  display: flex;

  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({
    padding: "0px",
    width: "95%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "70%",
    margin: "0px",
  })}
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 100%;
  ${mobile({
    margin: "5px",
  })}
`;
const Label = styled.label`
  flex: 1;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 300;
`;
const Input = styled.input`
  flex: 1;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;

const Select = styled.select`
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;
const Option = styled.option`
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;
const Button = styled.button`
  width: 100%;
  border: none;
  height: 40px;
  font-size: 20px;
  color: #deba59;
  background-color: #002035;
  border-radius: 20px;
  margin-top: 20px;
`;

const TextArea = styled.textarea``;

const NewReservation = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [inputs, setInputs] = useState([]);
  const user = useSelector((state) => state?.user?.currentUser);
  const id = user._id;
  const email = user.email;
  // console.log(id);
  const { instance } = useUserRequest();
  const navigate = useNavigate();
  document.title =
    "Restaurant Reservierungen - Schnell und einfach mit v&cdelivery";

  document
    .querySelector("meta[name='description']")
    .setAttribute(
      "content",
      "Auf unserer Reservierungsseite für Restaurants können Sie bequem und einfach Ihren Tisch in einem unserer ausgewählten Restaurants reservieren. Wählen Sie aus einer Vielzahl von Optionen wie Tischgröße, Datum und Uhrzeit aus und genießen Sie einen unvergesslichen Abend. Profitieren Sie von unserem einfachen Buchungsprozess und sichern Sie sich jetzt Ihren Tisch."
    );

  //Get Restaurant
  useEffect(() => {
    const getRestaurants = async () => {
      try {
        const res = await publicRequest.get("/restaurant");
        setRestaurants(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRestaurants();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setInputs((prev) => {
      return { ...prev, [e?.target?.name]: e?.target?.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reservation = {
      ...inputs,
      owner: id,
      status: "Pending",
      email: email,
    };

    try {
      const res = await instance.post(`/reservation`, reservation);
      navigate("/reservationsucess", {
        state: {
          reservationData: res.data,
        },
      });

      console.log(res.data);
    } catch (error) {}
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>V&C Delivery</Title>
          <SubTitle>NEUE RESERVIERUNG</SubTitle>
          <p>
            Restaurantreservierung, Kuchenreservierung,
            Catering-Lieferreservierung
          </p>
          <Form>
            <InputDiv>
              <Label for="restaurants">Wählen Sie ein Restaurant</Label>

              <Select name="restaurant" onChange={handleChange}>
                <Option selected disabled>
                  Bitte wählen Sie ein Restaurant aus
                </Option>
                {restaurants.map((r) => (
                  <Option key={r._id} value={r._id}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </InputDiv>
            <InputDiv>
              <Label>Beschreibung</Label>
              <Input
                placeholder="E.g. Geburtstag, Hochzeit, Antrag, etc"
                onChange={handleChange}
                name="description"
              />
            </InputDiv>
            <InputDiv>
              <Label>Personenzahl</Label>
              <Input type="Number" onChange={handleChange} name="pers" />
            </InputDiv>
            <InputDiv>
              <Label>Datum</Label>
              <Input type="date" onChange={handleChange} name="date" />
            </InputDiv>
            <InputDiv>
              <Label>Zeit</Label>
              <Input type="time" onChange={handleChange} name="time" />
            </InputDiv>
            <InputDiv>
              <Label>Zusätzliche Anmerkung</Label>
              <TextArea
                rows="2"
                onChange={handleChange}
                name="extraNote"
              ></TextArea>
            </InputDiv>

            <Button onClick={handleSubmit}>CREATE</Button>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default NewReservation;
