import styled from "styled-components";
import Pastry from "./Pastry";
import { PastryData } from "../../../dummydata/data";
import { mobile, tablet } from "../../../responsive";
import { useState, useEffect } from "react";
import { publicRequest } from "./../../../utils/requestMethod";
import Pastry2 from "./Pastry2";
import { pastryType } from "../../../utils/customHooks/types";
import { Navigate, useNavigate } from "react-router-dom";
import Navbar from "./../../../components/navbar/Navbar";

const Container = styled.div`
  /* height: 70vh; */
  padding-top: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
  flex-direction: column;
  ${mobile({
    width: "100vw",
    margin: "0px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({ padding: "0px", paddingBottom: "50px" })}
`;

const Title = styled.h1`
  color: #002035;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 100%;

  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
  })}
  ${tablet({ padding: "0px", width: "100%" })}
`;

const TopContainer = styled.div``;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  ${mobile({ margin: "10px 0px" })}
  border-radius: 10px;
`;
const Option = styled.option`
  padding: 10px;
  margin: 10px;
`;

const Button = styled.button`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  ${mobile({ margin: "10px 10px" })}
`;

const Pastries = () => {
  const [pastrys, setPastrys] = useState([]);
  const [type, setType] = useState("");
  // const [discounted, setDiscounted] = useState(null);
  // const [disPas, setDisPas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getPastrys = async () => {
      try {
        const res = await publicRequest.get(
          type ? `/pastry?type=${type}` : `/pastry`
        );
        setPastrys(res.data);
      } catch (error) {}
    };
    getPastrys();
  }, [type]);

  //Filter Pastry according to discount

  // console.log(result);

  // useEffect(() => {
  //   const checkDiscount = async () => {
  //     console.log(discounted);
  //     const result = pastrys.filter((p) => {
  //       return p.discountStatus === discounted;
  //     });
  //     console.log(result);
  //     setDisPas(result);
  //   };
  //   checkDiscount();
  // }, [discounted, pastrys]);

  const handleClick = () => {
    navigate("/discountedpastries");
  };

  return (
    <>
      <Container>
        <Title>Gebäck/Pudding</Title>
        {/* <Select onChange={(e) => setDiscounted(e.target.value)}>
        <Option>Pick Discount Option</Option>
        <Option value="true">True</Option>
        <Option value="false">False</Option>
      </Select> */}
        <TopContainer>
          <Select onChange={(e) => setType(e.target.value)}>
            <Option>Wählen Sie eine Gebäckart aus</Option>

            {pastryType.map((pt) => (
              <Option key={pt.id} value={pt.name}>
                {pt.name}
              </Option>
            ))}
          </Select>

          {/* <Button onClick={handleClick}>Ermäßigtes Gebäck</Button> */}
        </TopContainer>
        <Wrapper>
          {pastrys.map((item) => (
            <Pastry item={item} key={item._id} />
          ))}
        </Wrapper>
      </Container>
    </>
  );
};

export default Pastries;
