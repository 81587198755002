import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "./redux/userRedux";

const BASE_URL = process.env.REACT_APP_BASEURL;

const username = "pass";
const password = "vcdsecretauthpass";

let TOKEN = "";

if (localStorage.getItem("persist:root")) {
  TOKEN = JSON?.parse(JSON?.parse(localStorage.getItem("persist:root"))?.user)
    ?.currentUser?.accessToken;
}

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  auth: { username, password },
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  auth: { username, password },
  headers: { token: `Bearer ${TOKEN}` },
});

const newRefresh = async (dispatch, instance) => {
  // window.location.reload();
  const res = await instance.post("/auth/jwt/refresh");
  dispatch(refreshToken(res.data));

  return res.data;
};

export const useUserRequest = () => {
  const user = useSelector((state) => state?.user?.currentUser) || null;
  const dispatch = useDispatch();
  // console.log("OLD TOKEN", user?.accessToken);
  let instance = axios.create({
    baseURL: BASE_URL,
    auth: { username, password },

    headers: {
      token: `Bearer ${user?.accessToken}`,
      refreshtoken: user?.refreshToken,
      id: user?._id,
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      // && !prevRequest?.sent
      if (error?.response?.status === 403 && !prevRequest?.sent) {
        console.log(error?.response);
        const res = await newRefresh(dispatch, instance);
        window.location.reload();
        console.log("NEW TOKEN", res?.accessToken);
      }
      return Promise.reject(error);
    }
  );

  return { instance };
};
