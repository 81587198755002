import styled from "styled-components";
import { mobile, tablet } from "../../../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 25vw;
  height: 30vh;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 20px;
  margin-top: 20px;

  ${mobile({
    width: "100%",
    marginTop: "10px",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "20px",
    height: "40vh",
  })}
  ${tablet({
    width: "45%",
    marginLeft: "12.5px",
    marginRight: "12.5px",
    marginBottom: "5px",
  })}
`;

const Wrapper = styled.div`
  color: #002035;
  width: 100%;
  height: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding-bottom: 50px;
  ${mobile({ width: "100%", paddingBottom: "50px" })}
  ${tablet({ width: "100%", paddingBottom: "40px" })}
`;

const InnerWrapper = styled.div`
  width: 90%;
  height: 100%;
  margin-top: 10px;
`;

const ImageContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 75%;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const Name = styled.p`
  font-size: 1em;
`;

const MiniWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Price = styled.p`
  font-weight: 500;
  font-size: 1em;
`;

const Pastry = ({ item }) => {
  return (
    <Container>
      <Link to={`/pastry/${item._id}`}>
        <Wrapper>
          <InnerWrapper>
            <ImageContainer>
              <Image src={item.image} />
            </ImageContainer>
            <MiniWrapper>
              <Name>{item.name}</Name>
            </MiniWrapper>
            <PriceContainer>
              <Price>€ {item.price}</Price>
            </PriceContainer>
          </InnerWrapper>
        </Wrapper>
      </Link>
    </Container>
  );
};

export default Pastry;
