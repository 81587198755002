import React from "react";
import styled from "styled-components";
import { mobile } from "../../responsive";
import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Pinterest,
  Room,
  Twitter,
  Public,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Logo = styled.h1``;

const Desc = styled.p`
  margin: 20px 0px;
  justify-content: center;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`;
const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;
const Payment = styled.img`
  width: 100%;
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>V & C Delivery</Logo>
        <Desc></Desc>
        <SocialContainer>
          <a href="https://web.facebook.com/people/VC-personal-service/100063710063425/">
            <SocialIcon color="3B5999">
              <Facebook />
            </SocialIcon>
          </a>
          <SocialIcon color="E4405F">
            <Twitter />
          </SocialIcon>
          <a href="https://www.instagram.com/v_c_delivery/?igshid=YmMyMTA2M2Y%3D">
            <SocialIcon color="55ACEE">
              <Instagram />
            </SocialIcon>
          </a>

          <SocialIcon color="E60023">
            <Pinterest />
          </SocialIcon>
        </SocialContainer>
      </Left>

      <Center>
        <Title>Useful Links</Title>
        <List>
          <Link to="ueber-uns">
            <ListItem>Uber Uns</ListItem>
          </Link>

          <Link to="kontakt">
            <ListItem>Kontakt</ListItem>
          </Link>

          <Link to="tac">
            <ListItem>Geschäftsbedingung</ListItem>
          </Link>

          <Link to="impressum">
            <ListItem>Impressum</ListItem>
          </Link>

          <Link to="team">
            <ListItem>Team</ListItem>
          </Link>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} /> Kaiserin Augusta alle 95
          10589
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> +49 (0) 151 716 731 85
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> 030 405 521 26 (Fax)
        </ContactItem>
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} /> info@vcdelivery.de
        </ContactItem>
        {/* <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" /> */}

        <ContactItem>
          <Public style={{ marginRight: "10px" }} />{" "}
          <a href="https://ebhota.github.io/">Made with Love by Papijo</a>
        </ContactItem>
        <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
      </Right>
    </Container>
  );
};

export default Footer;
