import {
  loginFailure,
  loginStart,
  loginSuccess,
  logoutUser,
  refreshToken,
} from "./userRedux";

import { clearCart } from "./cartRedux";
import { publicRequest } from "./../requestMethod";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (error) {
    dispatch(loginFailure(error?.response?.data?.message));
    // console.log(error.response.data.message);
  }
};

export const logout = async (dispatch, user, cart) => {
  try {
    const res = await publicRequest.post("/auth/logout", user);
    dispatch(logoutUser(res.data));
    dispatch(clearCart);
    window.localStorage.clear();
  } catch (err) {}
};

export const refresh = async (dispatch) => {
  // window.location.reload();
  const res = await publicRequest.post("/auth/jwt/refresh");
  dispatch(refreshToken(res.data));

  return res.data;
};
