import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 25vw;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-left: 70px;
  margin-right: 70px;

  position: relative; */
  ${mobile({
    width: "95%",
    marginBottom: "20px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  })}
  ${tablet({
    padding: "0px",
    width: "40vw",
    margin: "20px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  })}
`;

const Wrapper = styled.div`
  color: #002035;

  width: 100;
  height: 100%;
  ${mobile({
    padding: "0px",
    width: "100%",
    margin: "0px",
  })}

  ${tablet({
    padding: "0px",
    width: "100%",
    height: "85%",
    margin: "0px",
  })}
`;

const Title = styled.h3`
  margin-bottom: 10px;
  text-align: center;
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
`;
const Info = styled.div`
  /* top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Desc = styled.p`
  text-align: center;
`;

const Button = styled.button`
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  cursor: pointer;
  background-color: #002035;
  color: #deba59;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
`;

const HomecardItem = ({ item }) => {
  return (
    <Container>
      <Wrapper>
        <Link to={item.url}>
          <Title>{item.title} </Title>
          <ImageContainer>
            <Image src={item.image} />
          </ImageContainer>
          <Info>
            <Desc>{item.desc}</Desc>

            <Button>{item.cta}</Button>
          </Info>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default HomecardItem;
