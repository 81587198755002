import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import { publicRequest } from "../../utils/requestMethod";
import { useNavigate } from "react-router";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #deba59 !important;
  color: #002035 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({
    padding: "0px",
    width: "90%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "70%",
    margin: "0px",
  })}
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
`;
const SubTitle = styled.h2`
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 100%;
  ${mobile({
    margin: "5px",
  })}
`;
const Label = styled.label`
  flex: 1;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
`;
const Input = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
`;
const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  font-size: 20px;
  color: #deba59;
  background-color: #002035;
  border-radius: 30px;
  margin-top: 20px;
`;

const Span = styled.button`
  width: 100%;
  /* border: none; */
  border: 1px solid #002035;
  padding: 15px 20px;
  font-size: 20px;
  background-color: white;
  color: #002035;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Register = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email);
    try {
      if (
        password === confirmPassword &&
        password !== "" &&
        firstname !== "" &&
        lastname !== "" &&
        email !== ""
      ) {
        const res = await publicRequest.post("/auth/user/register", {
          firstname,
          lastname,
          password,
          email,
        });
        navigate("/login");
      } else {
        setErrorMessage("Enter Credentials Properly!!!");
      }
      setFirstname("");
      setLastname("");
      setPassword("");
      setConfirmPassword("");
      setEmail("");
    } catch (error) {
      console.log(error.response.data.message);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>V&C Delivery</Title>
        <SubTitle>Anmeldung</SubTitle>
        {errorMessage && (
          <Stack sx={{ margin: "10px" }} spacing={2}>
            <Alert
              onClose={() => {
                setErrorMessage("");
              }}
              severity="error"
            >
              {errorMessage}
            </Alert>
          </Stack>
        )}
        <Form onSubmit={handleSubmit}>
          <InputDiv>
            <Label>Vorname</Label>
            <Input
              placeholder="Jonathan"
              value={firstname}
              onChange={({ target }) => setFirstname(target.value)}
            />
          </InputDiv>
          <InputDiv>
            <Label>Nachname</Label>
            <Input
              placeholder="Moss"
              value={lastname}
              onChange={({ target }) => setLastname(target.value)}
            />
          </InputDiv>
          <InputDiv>
            <Label>Email</Label>
            <Input
              placeholder="jm@email.com"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              type="email"
            />
          </InputDiv>
          <InputDiv>
            <Label>Passwort</Label>
            <Input
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
          </InputDiv>
          <InputDiv>
            <Label>Passwort bestätigen</Label>
            <Input
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
          </InputDiv>
          <Button>Anmeldung</Button>
        </Form>
        <Link to="/login">
          <Span>Login </Span>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Register;
