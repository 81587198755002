import React from "react";
import styled from "styled-components";
import { GiShoppingCart } from "react-icons/gi";
import Badge from "@mui/material/Badge";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  SupervisedUserCircle,
  SupervisedUserCircleOutlined,
} from "@mui/icons-material";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  padding-right: 20px;

  @media (max-width: 815px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    padding-top: 3.5rem;
    z-index: 10;
    transition: transform 0.3s ease-in-out;
    color: #deba59;
    background-color: #002035;
    li {
      font-size: 20px;
    }
  }
`;

const Li = styled.li`
  padding: 15px 10px;
  font-size: 1.2vw;
  cursor: pointer;
  /* color: #deba59;
  background-color: #002035; */

  @media only screen and (min-width: 481px) and (max-width: 815px) {
    color: #deba59;
    background-color: #002035;
    font-size: 1.2vh;
    padding-bottom: 10px;
  }
  /* 
  @media (max-width: 768px) {
    color: #deba59;
    background-color: #002035;
    font-size: 1.5vh;
    .icon {
      font-size: 35px;
    }
  } */
`;

const LinkImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

const RightNav = ({ open }) => {
  const user = useSelector((state) => state?.user?.currentUser) || null;
  const quantity = useSelector((state) => state.cart.quantity);
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem("persist:vcd");
    navigate("/login");
    window.location.reload();
  };

  return (
    <Ul open={open}>
      <Li>
        <Link to="/reservierung">Reservierungen</Link>
      </Li>
      <Link to="/essen-bestellen">
        <Li>Essensbestellung</Li>
      </Link>
      {/* <Link to="newfooddelivery">
        <Li>Lebensmittellieferservice</Li>
      </Link> */}
      <Link to="/lebensmittel-online-bestellen">
        <Li>Einkaufszentrum</Li>
      </Link>
      <Link to="/pastries">
        <Li>Gebäck/Pudding</Li>
      </Link>
      <Link to="/waescheservice">
        <Li>Wäscherei</Li>
      </Link>
      <Link to="/warenkorb">
        <Li>
          <Badge badgeContent={quantity} color="secondary">
            <GiShoppingCart className="cart-icon" />
          </Badge>
        </Li>
      </Link>
      {user === null ? (
        <span></span>
      ) : (
        <Link to="/profile">
          <Li>
            {user.lastname} {user.firstname}
          </Li>
        </Link>
      )}
      {user !== null ? (
        <Li onClick={handleLogout}>Logout</Li>
      ) : (
        <Link to="/login">
          <Li>Log In</Li>
        </Link>
      )}
    </Ul>
  );
};

export default RightNav;
