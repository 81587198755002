import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import useGetTotalCartCost from "../../utils/customHooks/useGetTotalCartCost";
import StripeCheckout from "react-stripe-checkout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { publicRequest, useUserRequest } from "../../utils/requestMethod";
import { clearCart } from "../../utils/redux/cartRedux";
import Paypal from "../../components/payment/Paypal";
import Navbar from "../../components/navbar/Navbar";

const KEY = process.env.REACT_APP_STRIPE;

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  padding-bottom: 4vh;
`;
const Wrapper = styled.div``;
const ReviewWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;
const ReviewOrder = styled.div`
  width: 30%;
  margin: auto;
  flex: 5;
  ${mobile({ padding: "5px" })}
`;

const Left = styled.div`
  flex: 1;
  ${mobile({ display: "none" })}
`;
const Right = styled.div`
  flex: 1;
  ${mobile({ display: "none" })}
`;

const ReviewHeading = styled.div`
  text-align: center;
  font-size: 25px;
  margin-bottom: 20px;
`;

const ReviewBodyOne = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineBody = styled.div`
  display: flex;
  font-size: 20px;
  margin-bottom: 20px;
`;
const LineBodyLeft = styled.div`
  flex: 1;
`;
const LineBodyRight = styled.div`
  flex: 1;
  text-align: right;
`;

const StripeContainer = styled.div`
  margin: 10px;
  align-self: center;
  width: 50%;
  ${mobile({ width: "80%" })}
  ${tablet({ width: "80%" })}
`;

const Button = styled.button`
  width: 100%;
  font-size: 1.5vw;
  border: none;
  border-radius: 35px;
  height: 5vh;
  background-color: #002035;
  color: #deba59;
  align-self: center;
  cursor: pointer;
  ${mobile({ fontSize: "3.5vw", width: "100%" })}
  ${tablet({ fontSize: "2.5vw", width: "100%" })}
`;

const PaypalContainer = styled.div`
  margin: 10px;
  align-self: center;
  width: 50%;
  ${mobile({ width: "80%" })}
  ${tablet({ width: "80%" })}
`;

const Review = () => {
  const { totalCartCost } = useGetTotalCartCost();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.currentUser);
  const [stripeToken, setStripeToken] = useState(null);
  const dispatch = useDispatch();

  const onToken = async (token) => {
    setStripeToken(token);
  };

  const paymentTotal = totalCartCost + cart.shipping.shippingCost;
  const PaypalTotal = paymentTotal.toString();

  const StripePaymentTotal = paymentTotal * 100;

  useEffect(() => {
    const makeOrder = async () => {
      try {
        const res = await publicRequest.post(`/stripe/checkout/payment`, {
          tokenId: stripeToken?.id,
          amount: StripePaymentTotal,
        });

        navigate("/success", {
          state: {
            paymentData: res.data,
            products: cart,
            amount: paymentTotal,
            paymentType: "Stripe",
          },
        });

        dispatch(clearCart({ cart }));
      } catch (error) {}
    };
    if (stripeToken) {
      makeOrder();
    }
  }, [
    StripePaymentTotal,
    cart,
    cart.shipping.shippingCost,
    dispatch,
    navigate,
    paymentTotal,
    stripeToken,
    stripeToken?.id,
    totalCartCost,
  ]);

  const Paylater = async () => {
    let data = {
      id: new Date().toISOString(),
    };
    navigate("/success", {
      state: {
        paymentType: "Pay Later",
        products: cart,
        amount: paymentTotal,
        paymentData: data,
      },
    });

    console.log({
      paymentType: "Pay Later",
      products: cart,
      amount: paymentTotal,
      paymentData: data,
    });
    dispatch(clearCart({ cart }));
  };

  // console.log(stripeToken);
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <ReviewWrapper>
            <Left></Left>
            <ReviewOrder>
              <ReviewHeading>Confirm your Order</ReviewHeading>
              <ReviewBodyOne>
                <LineBody>
                  <LineBodyLeft>Name:</LineBodyLeft>
                  <LineBodyRight>
                    {user.lastname} {user.firstname}
                  </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft>UserID:</LineBodyLeft>
                  <LineBodyRight>{user._id} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft>Email:</LineBodyLeft>
                  <LineBodyRight>{user.email} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft> Cart Total</LineBodyLeft>
                  <LineBodyRight>€{totalCartCost} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft> Shipping Fee</LineBodyLeft>
                  <LineBodyRight>€{cart.shipping.shippingCost} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft> Order Total</LineBodyLeft>
                  <LineBodyRight>
                    €{totalCartCost + cart.shipping.shippingCost}
                  </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft>Delivery Address</LineBodyLeft>
                  <LineBodyRight>{cart.shipping.address} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft>Receiver</LineBodyLeft>
                  <LineBodyRight>{cart.shipping.person} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft>Phone Number</LineBodyLeft>
                  <LineBodyRight>{cart.shipping.phone} </LineBodyRight>
                </LineBody>
                <LineBody>
                  <LineBodyLeft>Extra Delivery Instructions</LineBodyLeft>
                  <LineBodyRight>{cart.shipping.extra} </LineBodyRight>
                </LineBody>

                {/* Stripe */}
                <StripeContainer>
                  <StripeCheckout
                    name="V & C Delivery Services"
                    image="https://images.unsplash.com/photo-1472851294608-062f824d29cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHNob3BwaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                    description={`Your total is €${
                      totalCartCost + cart.shipping.shippingCost
                    }`}
                    currency="EUR"
                    email={user.email}
                    amount={StripePaymentTotal}
                    token={onToken}
                    stripeKey={KEY}
                  >
                    <Button>Pay with Stripe</Button>
                  </StripeCheckout>
                </StripeContainer>

                {/* Paypal */}
                <PaypalContainer>
                  <Paypal amount={PaypalTotal} />
                </PaypalContainer>

                <StripeContainer>
                  <Button onClick={Paylater}>Pay Later</Button>
                </StripeContainer>
                {/* <div style={{ maxWidth: "750px", minHeight: "200px" }}>
                <PayPalScriptProvider
                  options={{
                    "client-id": "test",
                    components: "buttons",
                    currency: "USD",
                  }}
                >
                  <ButtonWrapper currency={currency} showSpinner={false} />
                </PayPalScriptProvider>
              </div> */}
              </ReviewBodyOne>
            </ReviewOrder>
            <Right></Right>
          </ReviewWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default Review;
