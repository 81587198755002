import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import { publicRequest } from "./../../utils/requestMethod";
import { useNavigate } from "react-router";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  ${mobile({
    padding: "0px",
    width: "95%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "75%",
    margin: "0px",
  })}
`;

const Input = styled.input`
  height: 5vh;
  margin: 5px;
  font-size: 20px;
`;
const Button = styled.button`
  height: 4vh;
  margin: 5px;
  border: none;
  border-radius: 2vh;
  background-color: #002035;
  color: #deba59;
  cursor: pointer;
`;

const ForgotPassword = () => {
  const [input, setInput] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await publicRequest.put("/users/change/password", { email: input });
      navigate("/verifychangedpassword");
    } catch (error) {}
  };
  return (
    <Container>
      <Wrapper>
        <Input
          type="email"
          placeholder="example@email.com"
          onChange={handleChange}
        />
        <Button onClick={handleSubmit}>Send Email</Button>
      </Wrapper>
    </Container>
  );
};

export default ForgotPassword;
