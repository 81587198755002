import styled from "styled-components";
import { mobile, tablet } from "../../../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 25vw;
  height: 40vh;
  padding-top: 20px;
  margin-left: 20px;
  margin-right: 10px;

  ${mobile({
    width: "100%",
    marginTop: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  })}
  ${tablet({
    width: "45%",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px",
  })}
`;

const Wrapper = styled.div`
  color: #002035;
  width: 100%;
  height: 70%;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding-bottom: 50px;
  ${mobile({ width: "100%", paddingBottom: "50px" })}
  ${tablet({ width: "100%", paddingBottom: "50px" })}
`;

const InnerWrapper = styled.div`
  width: 90%;
  height: 100%;
  margin-top: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 90%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const Name = styled.p`
  font-size: 1em;
  line-height: 90%;
`;

const Desc = styled.p`
  text-align: left;
  font-size: 0.8em;
`;

const MiniWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Price = styled.p`
  font-weight: 400;
  font-size: 1.2em;
`;
const Pastry2 = ({ item }) => {
  return (
    <Container>
      <Link to={`/pastry/${item._id}`}>
        <Wrapper>
          <InnerWrapper>
            <Image src={item.image} />
            <MiniWrapper>
              <Name>{item.name}</Name>
              <Price>
                <b> € {item.price} </b>
              </Price>
            </MiniWrapper>
            {/* <Desc>{item.desc}</Desc> */}
          </InnerWrapper>
        </Wrapper>
      </Link>
    </Container>
  );
};

export default Pastry2;
