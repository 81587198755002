import React from "react";
import styled from "styled-components";
import GroceryProduct from "./GroceryProduct";
import { mobile, tablet } from "../../../responsive";
import { useState } from "react";
import { useEffect } from "react";
import { publicRequest } from "./../../../utils/requestMethod";
import { productType } from "../../../utils/customHooks/types";
import { useNavigate } from "react-router";
import Navbar from "./../../../components/navbar/Navbar";

const Container = styled.div`
  /* height: 70vh; */
  background-color: #deba59;
  padding-top: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
  flex-direction: column;
  ${mobile({
    width: "100vw",
    margin: "0px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({ padding: "0px", paddingBottom: "50px" })}
`;

const Title = styled.h2`
  color: #002035;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 100%;

  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
  })}
  ${tablet({ padding: "0px", width: "100%" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  ${mobile({ margin: "10px 0px" })}
  border-radius: 10px;
`;
const Option = styled.option`
  padding: 10px;
  margin: 10px;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
`;

const TopContainer = styled.div``;

const Button = styled.button`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  ${mobile({ margin: "10px 10px" })}
`;

const ProductWrapper = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item) => (
        <GroceryProduct key={item._id} item={item} />
      ))}
    </Wrapper>
  );
};

const GroceryProducts = () => {
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");

  const keys = ["name"];

  const search = (d) => {
    return d.filter((item) =>
      keys.some((key) => {
        return item[key].includes(query);
      })
    );
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const getGroceryProduct = async () => {
      const res = await publicRequest.get(`/groceryproduct`);
      setProducts(res.data);
      console.log(res.data);
    };
    getGroceryProduct();
  }, []);

  const handleClick = () => {
    navigate("/newgroceryorder");
  };
  return (
    <>
      <Navbar />
      <Container>
        <Title>Produkte einkaufen</Title>
        <TopContainer>
          <Button onClick={handleClick}>
            Brauchen Sie noch etwas, machen Sie Ihre Einkaufsliste
          </Button>
        </TopContainer>
        <Input placeholder="search...." onChange={handleChange} />
        <ProductWrapper data={search(products)} />
      </Container>
    </>
  );
};

export default GroceryProducts;
