import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { publicRequest } from "../../../utils/requestMethod";
import { addPastry } from "../../../utils/redux/cartRedux";
import { mobile, tablet } from "../../../responsive";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Navbar from "../../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  ${mobile({ paddingBottom: "10px", paddingTop: "10px" })}
`;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mobile({ padding: "0px" })}
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 70%;
  height: 40vh;
  border-radius: 10px;
  background-color: white;
  ${tablet({ width: "90%", height: "30vh" })}
  ${mobile({
    flexDirection: "column",
    width: "90%",
    height: "70vh",
    display: "flex",
  })}
`;

const Left = styled.div`
  flex: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mobile({
    alignItems: "flex-start",
    flex: "1",
    height: "20%",
  })}
`;
const ImgContainer = styled.div`
  height: 80%;
  width: 90%;
  ${mobile({ marginTop: "20px", height: "90%" })}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${mobile({ alignItems: "flex-start", flex: "1" })}
`;
const InfoContainer = styled.div`
  height: 80%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mobile({ padding: "0px 20px", height: "90%", width: "100%" })}
`;

const Description = styled.div`
  width: 90%;
`;

const Title = styled.h2`
  font-weight: 400;
  ${tablet({ fontWeight: "400", fontSize: "20px" })}
`;

const Desc = styled.p`
  margin: 10px 0px;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Price = styled.div`
  font-weight: 300;
  font-size: 30px;
  flex: 1;
`;

const AddContainer = styled.div`
  display: flex;
  flex: 1;
  ${mobile({ width: "100%" })}
  float: right;
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  width: 100%;
  margin-left: auto;
`;

const Amount = styled.span`
  width: 30px;
  height: 30px;
  /* border-radius: 10px;
  border: 1px solid teal; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;
const Button = styled.button`
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  border: none;
  background-color: #002035;
  color: #deba59;
  cursor: pointer;
  border-radius: 25px;
  font-weight: 500;
`;

const PastryDetail = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [pastry, setPastry] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const pastryHasBeenAddedToCart = cart.pastryorders.find((p) => p._id === id);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get("/pastry/find/" + id);
        setPastry(res.data);
        console.log(res.data.price);
      } catch (error) {}
    };
    getProduct();
  }, [id]);

  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
  };

  const handleClick = () => {
    if (pastryHasBeenAddedToCart) {
      return setErrorMessage("Pastry has already been added to cart!");
    }
    //   return alert("Pastry has already been added to cart!");

    dispatch(addPastry({ ...pastry, quantity }));
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <InnerWrapper>
            <Left>
              <ImgContainer>
                <Image src={pastry.image} />
              </ImgContainer>
            </Left>
            <Right>
              <InfoContainer>
                <Description>
                  <Title>{pastry.name}</Title>
                  <Desc>{pastry.desc}</Desc>
                </Description>
                <PriceContainer>
                  <Price>€ {pastry.price} </Price>
                  <AddContainer>
                    <AmountContainer>
                      <RemoveCircle
                        style={
                          quantity === 1
                            ? { color: "gray" }
                            : { color: "#002035", cursor: "pointer" }
                        }
                        onClick={() => handleQuantity("dec")}
                      />
                      <Amount>{quantity} </Amount>
                      <AddCircle
                        style={{ cursor: "pointer" }}
                        onClick={() => handleQuantity("inc")}
                      />
                    </AmountContainer>
                  </AddContainer>
                </PriceContainer>

                <Button onClick={handleClick}>ADD TO CART</Button>
                {errorMessage && (
                  <Stack sx={{ margin: "10px" }} spacing={2}>
                    <Alert
                      onClose={() => {
                        setErrorMessage("");
                      }}
                      severity="error"
                    >
                      {errorMessage}
                    </Alert>
                  </Stack>
                )}
              </InfoContainer>
            </Right>
          </InnerWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default PastryDetail;
