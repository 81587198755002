import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { publicRequest } from "./../../utils/requestMethod";
import styled from "styled-components";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div``;

const Success = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState(null);
  const paymentData = location?.state?.paymentData;
  const amount = location.state.amount;
  const products = location.state.products;
  const user = useSelector((state) => state.user.currentUser);
  const paymentType = location.state.paymentType;

  useEffect(() => {
    const createOrder = async () => {
      try {
        const orderData = {
          owner: user._id,
          email: user.email,
          transactionId: paymentData.id,
          address: products.shipping.address,
          person: products.shipping.person,
          phoneNumber: products.shipping.phone,
          amount: amount,
          paymentType: paymentType,
          productorders: products.productorders,
          groceryproducts: products.groceryproducts,
          foodorders: products.foodorders,
          fooddeliverys: products.fooddeliverys,
          groceryorders: products?.groceryorders,
          pastryorders: products.pastryorders,
          laundryorders: products.laundryorders,
          date: new Date().toDateString(),
        };
        console.log(orderData.fooddeliverys);
        const res = await publicRequest.post("/order", orderData);
        setOrderId(res.data._id);

        // const timeout = setTimeout(() => {
        //   window.location.replace("/");
        // }, 3000);
        // return () => clearTimeout(timeout);
      } catch (error) {}
    };
    createOrder();
  }, [
    amount,
    products.fooddeliverys,
    products.foodorders,
    products?.groceryorders,
    products.laundryorders,
    products.pastryorders,
    products.shipping.address,
    products.shipping.person,
    products.shipping.phone,
    paymentData.id,
    user._id,
    user.email,
    paymentType,
    products.productorders,
    products.groceryproducts,
  ]);
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          {orderId
            ? `Order has been created successfully. Your order number is ${orderId}`
            : `Successfull. Your order is being prepared...`}
          {/* <Link to="/">
          {" "}
          <button style={{ padding: 10, marginTop: 20 }}>
            Back to Homepage
          </button>
        </Link> */}
        </Wrapper>
      </Container>
    </>
  );
};

export default Success;
