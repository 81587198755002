import React, { useState } from "react";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import { Add, Remove, Delete, DeleteOutline } from "@mui/icons-material";
import { tablet, mobile } from "../../responsive";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useUserRequest } from "../../utils/requestMethod";
import { useHistory } from "react-router";
import useGetTotalCartCost from "../../utils/customHooks/useGetTotalCartCost";
import "./css/cart.css";

import {
  clearCart,
  increaseFoodorderQuantity,
  increaseGroceryProductQuantity,
  increaseLaundryQuantity,
  increasePastryQuantity,
  increaseProductQuantity,
  reduceFoodorderQuantity,
  reduceGroceryProductQuantity,
  reduceLaundryQuantity,
  reducePastryQuantity,
  reduceProductQuantity,
  removeFooddelivery,
  removeFoodorder,
  removeGroceryorder,
  removeGroceryProduct,
  removeLaundry,
  removePastry,
  removeProduct,
} from "../../utils/redux/cartRedux";
import EmptyCartItem from "../../components/cart/EmptyCartItem";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`;
const Wrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  ${mobile({ width: "95%" })}
  justify-content: center;
  align-items: center;
`;
const Title = styled.h1`
  text-align: center;
  font-weight: 600;
  font-size: 3em;
  margin: 30px;
`;
const Basket = styled.div`
  margin-bottom: 1vh;
  width: 100%;
`;
const CartHeading = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  ${mobile({ fontSize: "4vw", marginBottom: "5px" })}
  ${tablet({ fontSize: "2.5vw" })}
`;
const CartItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  /* background-color: blue; */
`;
const ItemWrapper = styled.div`
  display: flex;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 18vh;
  /* ${mobile({ height: "12vh" })}
  ${tablet({ height: "17vh" })} */
  ${mobile({ height: "10vh" })}
  ${tablet({ height: "12vh" })}
  flex: 10;
`;

const DeleteIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8vh;
  background-color: #002035;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: red;
  flex: 1;
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const ProductImage = styled.img`
  width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 30px;
  ${mobile({ marginLeft: "15px" })}
`;
const Right = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  font-size: 1.3em;
`;

const QuantityContainer = styled.div`
  flex: 3;
`;

const FoodDeliveryItemWrapper = styled.div`
  display: flex;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 18vh;
  ${mobile({ height: "10vh" })}
  ${tablet({ height: "12vh" })}
  flex: 10;
`;

const FoodDeliveryQuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  height: 80%;
  margin-left: 30px;
  ${mobile({ marginLeft: "15px" })}
`;
const FoodDeliveryOrderId = styled.p`
  font-size: 1.8vw;
  font-weight: 500;
  ${mobile({ fontSize: "3vw" })}
  ${tablet({ fontSize: "2.2vw" })}
`;
const FoodDeliveryName = styled.p`
  font-size: 1.4vw;
  ${mobile({ fontSize: "3vw" })}
  ${tablet({ fontSize: "2vw" })}
`;
const FoodServiceCharge = styled.p`
  font-size: 1.4vw;
  color: #a6a2a2;
  ${mobile({ fontSize: "3vw" })}
  ${tablet({ fontSize: "2vw" })}
`;
const SP = styled.span`
  color: #002035;
  font-size: 1.4vw;
  ${mobile({ fontSize: "3vw" })}
  ${tablet({ fontSize: "2vw" })}
`;
const AddContainer = styled.div`
  display: flex;
  ${mobile({ width: "100%", fontSize: "3.5vw" })}
  ${tablet({ width: "100%", fontSize: "3vw" })}
  flex-direction: column;
  font-size: 2vw;
  width: 100%;
`;

const NameContainer = styled.div`
  font-size: 1.6vw;
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 100%;
  ${mobile({ width: "100%", fontSize: "3.5vw" })}
  ${tablet({ width: "100%", fontSize: "2.5vw" })}
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Amount = styled.span`
  width: 5vw;
  height: 5vw;
  border-radius: 10px;
  /* border: 1px solid teal; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 3px;
  font-size: 2vw;
  ${mobile({ fontSize: "3.5vw" })}
`;

const PriceContainer = styled.div`
  flex: 1;
  text-align: center;
`;
const Price = styled.p`
  font-weight: 500;
  font-size: 2.5vw;
  ${mobile({ width: "100%", fontSize: "3.5vw" })}
  ${tablet({ width: "100%", fontSize: "2.5vw" })}
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Total = styled.div`
  font-size: 2.5vw;
  margin-right: 10px;
  ${mobile({ fontSize: "6vw" })}
  ${tablet({ fontSize: "3vw" })}
`;
const TotalAmount = styled.div`
  margin-right: 4vw;
  font-size: 2.5vw;
  ${mobile({ fontSize: "6vw" })}
  ${tablet({ fontSize: "3vw" })}
`;
const FoodDeliveryWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const FoodDeliveryOrder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 10;
`;

const FoodDeliveryWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;
const FoodDeliveryIcon = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002035;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: red;
  margin-bottom: 10px;
`;

const FoodDeliveryTitle = styled.h2`
  margin-bottom: 20px;
`;

const FoodDeliveryContainer = styled.div`
  display: flex;
`;

const FoodDeliveryLeft = styled.div`
  flex: 1;
  font-size: 3.5vh;
  ${mobile({ fontSize: "4.5vw" })}
  ${tablet({ fontSize: "3.5vw" })}
`;
const FoodDeliveryRight = styled.div`
  flex: 1;
  font-size: 3.5vh;
  ${mobile({ fontSize: "4.5vw" })}
  ${tablet({ fontSize: "3.5vw" })}
`;

const FoodDeliveryButton = styled.button`
  height: 40px;
  background-color: #002035;
  color: red;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  width: 50%;
  margin-top: 20px;
  align-self: center;
`;

const GroceryOrder = styled.div`
  width: 95%;
  margin-bottom: 30px;
`;
const GroceryOrderTitle = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  ${mobile({ fontSize: "4vw" })}
  ${tablet({ fontSize: "2.5vw" })}
`;
const GroceryOrderTableContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
const Table = styled.table`
  align-self: center;
  width: 80%;
  padding-top: 10px;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  font-size: 2.5vh;
  ${mobile({ fontSize: "3vw", marginBottom: "0px" })}
  ${tablet({ fontSize: "2vw" })}
`;
const Tr = styled.tr`
  text-align: center;
`;
const Th = styled.th`
  text-align: center;
  border-bottom: 0.01px solid #efeaea;
  padding: 7px;
`;
const Td = styled.td`
  border-bottom: 0.1px solid #efeaea;
  padding: 7px;
`;
const GroceryOrderButton = styled.button`
  height: 40px;
  background-color: #002035;
  color: red;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  width: 100%;
`;

const GroceryDeleteIcon = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8vh;
  background-color: #002035;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: red;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: #002035;
  color: #deba09;
  border: none;
  border-radius: 3vh;
  width: 30vw;
  margin: 5px;
  height: 6vh;
  font-size: 1.5vw;
  /* align-self: center; */
  cursor: pointer;
  ${mobile({ width: "95%", height: "5vh", fontSize: "4vw" })}
  ${tablet({ width: "40vw", height: "5vh", fontSize: "2vw" })}
`;

const ClearCartButton = styled.button`
  width: 30vw;
  /* border: none; */
  border: 1px solid #002035;
  font-size: 20px;
  background-color: white;
  height: 6vh;
  color: #002035;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  ${mobile({ width: "95%", height: "5vh", fontSize: "4vw" })}
  ${tablet({ width: "40vw", height: "5vh", fontSize: "2vw" })}
`;

const ClearGroceryButton = styled.button`
  width: 25vw;
  /* border: none; */
  border: 1px solid #002035;
  font-size: 20px;
  background-color: white;
  height: 5vh;
  color: #002035;
  border-radius: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  align-self: center;
  ${mobile({ width: "80vw", height: "4vh", fontSize: "3vw" })}
  ${tablet({ width: "40vw", height: "4vh", fontSize: "1.8vw" })}
`;

const H4 = styled.h4``;

const Cart = () => {
  const { totalCartCost } = useGetTotalCartCost();
  const cart = useSelector((state) => state.cart);
  console.log(cart);

  const groceryorder = cart.groceryorders;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pushToCheckout = () => {
    navigate("/checkout", {
      state: {
        checkoutData: cart,
      },
    });
  };

  const deleteGroceryOrder = () => {
    dispatch(removeGroceryorder());
  };

  const clearCartState = () => {
    dispatch(clearCart());
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>Cart</Title>
          <Basket>
            <CartHeading>Pastry Orders</CartHeading>
            {/* Use Conditional Statement to render Pastry Items */}
            {cart.pastryorders.length === 0 ? (
              <EmptyCartItem item={`No pastries in cart!`} />
            ) : (
              cart.pastryorders.map((pastry) => {
                const disableReduceQuantity = pastry.quantity === 1;
                return (
                  <CartItem>
                    <ItemWrapper>
                      <Left>
                        <ProductImage src={pastry.image} />
                      </Left>
                      <Right>
                        <QuantityContainer>
                          <AddContainer>
                            <NameContainer>{pastry.name} </NameContainer>
                            <AmountContainer>
                              <IconButton
                                disabled={disableReduceQuantity}
                                onClick={() =>
                                  dispatch(
                                    reducePastryQuantity({
                                      ...pastry,
                                      quantity: pastry.quantity,
                                      price: pastry.price * pastry.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Remove />
                              </IconButton>

                              <Amount>{pastry.quantity} </Amount>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    increasePastryQuantity({
                                      ...pastry,
                                      quantity: pastry.quantity,
                                      price:
                                        (pastry.price - pastry.discount) *
                                        pastry.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Add />
                              </IconButton>
                            </AmountContainer>
                          </AddContainer>
                        </QuantityContainer>
                        <PriceContainer>
                          <Price>
                            €
                            {(pastry.price - pastry.discount) * pastry.quantity}{" "}
                          </Price>
                        </PriceContainer>
                      </Right>
                    </ItemWrapper>
                    <DeleteIcon>
                      <DeleteIcon
                        onClick={() =>
                          dispatch(removePastry({ ...pastry, id: pastry._id }))
                        }
                      >
                        <DeleteOutline
                          style={{
                            color: "red",
                            fontSize: "0.5em",
                            padding: "10px",
                          }}
                        />
                      </DeleteIcon>
                    </DeleteIcon>
                  </CartItem>
                );
              })
            )}
          </Basket>
          <Basket>
            <CartHeading>Grocery Product Orders</CartHeading>
            {/* Use Conditional Statement to render Product Items */}
            {cart.groceryproducts.length === 0 ? (
              <EmptyCartItem item={`No grocery products in cart!`} />
            ) : (
              cart.groceryproducts.map((product) => {
                const disableReduceQuantity = product.quantity === 1;
                return (
                  <CartItem>
                    <ItemWrapper>
                      <Left>
                        <ProductImage src={product.image} />
                      </Left>
                      <Right>
                        <QuantityContainer>
                          <AddContainer>
                            <NameContainer>{product.name} </NameContainer>
                            <AmountContainer>
                              <IconButton
                                disabled={disableReduceQuantity}
                                onClick={() =>
                                  dispatch(
                                    reduceGroceryProductQuantity({
                                      ...product,
                                      quantity: product.quantity,
                                      price: product.price * product.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Remove />
                              </IconButton>

                              <Amount>{product.quantity} </Amount>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    increaseGroceryProductQuantity({
                                      ...product,
                                      quantity: product.quantity,
                                      price:
                                        (product.price - product.discount) *
                                        product.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Add />
                              </IconButton>
                            </AmountContainer>
                          </AddContainer>
                        </QuantityContainer>
                        <PriceContainer>
                          <Price>€{product.price * product.quantity}</Price>
                        </PriceContainer>
                      </Right>
                    </ItemWrapper>
                    <DeleteIcon>
                      <DeleteOutline
                        onClick={() =>
                          dispatch(
                            removeGroceryProduct({
                              ...product,
                              id: product._id,
                            })
                          )
                        }
                        style={{
                          color: "red",
                          fontSize: "0.5em",
                          padding: "10px",
                        }}
                      />
                    </DeleteIcon>
                  </CartItem>
                );
              })
            )}
          </Basket>
          <Basket>
            <CartHeading>Discount Product Orders</CartHeading>
            {/* Use Conditional Statement to render Product Items */}
            {cart.productorders.length === 0 ? (
              <EmptyCartItem item={`No discount product orders in cart!`} />
            ) : (
              cart.productorders.map((product) => {
                const disableReduceQuantity = product.quantity === 1;
                return (
                  <CartItem>
                    <ItemWrapper>
                      <Left>
                        <ProductImage src={product.image} />
                      </Left>
                      <Right>
                        <QuantityContainer>
                          <AddContainer>
                            <NameContainer>{product.name} </NameContainer>
                            <AmountContainer>
                              <IconButton
                                disabled={disableReduceQuantity}
                                onClick={() =>
                                  dispatch(
                                    reduceProductQuantity({
                                      ...product,
                                      quantity: product.quantity,
                                      price: product.price * product.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Remove />
                              </IconButton>

                              <Amount>{product.quantity} </Amount>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    increaseProductQuantity({
                                      ...product,
                                      quantity: product.quantity,
                                      price:
                                        (product.price - product.discount) *
                                        product.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Add />
                              </IconButton>
                            </AmountContainer>
                          </AddContainer>
                        </QuantityContainer>
                        <PriceContainer>
                          <Price>€{product.price * product.quantity}</Price>
                        </PriceContainer>
                      </Right>
                    </ItemWrapper>
                    <DeleteIcon>
                      <DeleteOutline
                        onClick={() =>
                          dispatch(
                            removeProduct({ ...product, id: product._id })
                          )
                        }
                        style={{
                          color: "red",
                          fontSize: "0.5em",
                          padding: "10px",
                        }}
                      />
                    </DeleteIcon>
                  </CartItem>
                );
              })
            )}
          </Basket>
          <Basket>
            <CartHeading>Laundries</CartHeading>
            {/* Use Conditional Statement to render Laundry Items */}
            {cart.laundryorders.length === 0 ? (
              <EmptyCartItem item={`No laundry order in cart!`} />
            ) : (
              cart.laundryorders.map((laundry) => {
                const disableReduceQuantity = laundry.quantity === 1;
                return (
                  <CartItem>
                    <ItemWrapper>
                      <Left>
                        <ProductImage src={laundry.image} />
                      </Left>
                      <Right>
                        <QuantityContainer>
                          <AddContainer>
                            <NameContainer>{laundry.name} </NameContainer>
                            <AmountContainer>
                              <IconButton
                                disabled={disableReduceQuantity}
                                onClick={() =>
                                  dispatch(
                                    reduceLaundryQuantity({
                                      ...laundry,
                                      quantity: laundry.quantity,
                                      price: laundry.price * laundry.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Remove />
                              </IconButton>

                              <Amount>{laundry.quantity} </Amount>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    increaseLaundryQuantity({
                                      ...laundry,
                                      quantity: laundry.quantity,
                                      price: laundry.price * laundry.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Add />
                              </IconButton>
                            </AmountContainer>
                          </AddContainer>
                        </QuantityContainer>
                        <PriceContainer>
                          <Price>€{laundry.price * laundry.quantity} </Price>
                        </PriceContainer>
                      </Right>
                    </ItemWrapper>
                    <DeleteIcon>
                      <DeleteOutline
                        onClick={() =>
                          dispatch(
                            removeLaundry({ ...laundry, id: laundry._id })
                          )
                        }
                        style={{
                          color: "red",
                          fontSize: "0.5em",
                          padding: "10px",
                        }}
                      />
                    </DeleteIcon>
                  </CartItem>
                );
              })
            )}
          </Basket>

          <Basket>
            <CartHeading>Food Orders</CartHeading>
            {/* Use Conditional Statement to render Pastry Items */}
            {cart.foodorders.length === 0 ? (
              <EmptyCartItem item={`No food orders in cart!`} />
            ) : (
              cart.foodorders.map((foodorder) => {
                const disableReduceQuantity = foodorder.quantity === 1;
                return (
                  <CartItem>
                    <ItemWrapper>
                      <Left>
                        <ProductImage src={foodorder.image} />
                      </Left>
                      <Right>
                        <QuantityContainer>
                          <AddContainer>
                            <NameContainer>{foodorder.name} </NameContainer>
                            <AmountContainer>
                              <IconButton
                                disabled={disableReduceQuantity}
                                onClick={() =>
                                  dispatch(
                                    reduceFoodorderQuantity({
                                      ...foodorder,
                                      quantity: foodorder.quantity,
                                      price:
                                        foodorder.price * foodorder.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Remove />
                              </IconButton>

                              <Amount>{foodorder.quantity} </Amount>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    increaseFoodorderQuantity({
                                      ...foodorder,
                                      quantity: foodorder.quantity,
                                      price:
                                        foodorder.price * foodorder.quantity,
                                    })
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Add />
                              </IconButton>
                            </AmountContainer>
                          </AddContainer>
                        </QuantityContainer>
                        <PriceContainer>
                          <Price>
                            €{foodorder.price * foodorder.quantity}{" "}
                          </Price>
                        </PriceContainer>
                      </Right>
                    </ItemWrapper>
                    <DeleteIcon>
                      <DeleteOutline
                        onClick={() =>
                          dispatch(
                            removeFoodorder({ ...foodorder, id: foodorder._id })
                          )
                        }
                        style={{
                          color: "red",
                          fontSize: "0.5em",
                          padding: "10px",
                        }}
                      />
                    </DeleteIcon>
                  </CartItem>
                );
              })
            )}

            {/* <SummaryContainer>
            <LeftSummary></LeftSummary>
            <RightSummary>
              <DiscountContainer>
                <Discount>Discount</Discount>
                <DiscountAmount>$20</DiscountAmount>
              </DiscountContainer>
              <SubtotalContainer>
                <Subtotal>Subtotal</Subtotal>
                <SubtotalAmount>$420</SubtotalAmount>
              </SubtotalContainer>
            </RightSummary>
          </SummaryContainer> */}
          </Basket>

          <Basket>
            <CartHeading>Food Delivery Orders</CartHeading>
            {/* Use Conditional Statement to render Pastry Items */}
            {cart.fooddeliverys.length === 0 ? (
              <EmptyCartItem item={`No food delivery orders in cart!`} />
            ) : (
              cart.fooddeliverys.map((fd, index) => {
                const disableReduceQuantity = fd.quantity === 1;
                return (
                  <CartItem>
                    <FoodDeliveryItemWrapper>
                      {/* <Left>
                      <ProductImage src={fd.image} />
                    </Left> */}
                      <Right>
                        <FoodDeliveryQuantityContainer>
                          <FoodDeliveryOrderId>
                            {fd.orderId}{" "}
                          </FoodDeliveryOrderId>
                          <FoodDeliveryName>{fd.restaurant} </FoodDeliveryName>
                          <FoodDeliveryName>
                            {fd.restaurantAddress}{" "}
                          </FoodDeliveryName>
                          <FoodServiceCharge>
                            Service Charge: <SP>€5</SP>{" "}
                          </FoodServiceCharge>
                        </FoodDeliveryQuantityContainer>
                        <PriceContainer>
                          <Price>€{fd.price} </Price>
                        </PriceContainer>
                      </Right>
                    </FoodDeliveryItemWrapper>
                    <DeleteIcon
                      onClick={() => {
                        dispatch(removeFooddelivery({ ...fd, id: index }));
                      }}
                    >
                      <DeleteOutline
                        style={{
                          color: "red",
                          fontSize: "0.5em",
                          padding: "10px",
                        }}
                      />
                    </DeleteIcon>
                  </CartItem>
                );
              })
            )}

            {/* <SummaryContainer>
            <LeftSummary></LeftSummary>
            <RightSummary>
              <DiscountContainer>
                <Discount>Discount</Discount>
                <DiscountAmount>$20</DiscountAmount>
              </DiscountContainer>
              <SubtotalContainer>
                <Subtotal>Subtotal</Subtotal>
                <SubtotalAmount>$420</SubtotalAmount>
              </SubtotalContainer>
            </RightSummary>
          </SummaryContainer> */}
          </Basket>

          <Basket>
            <CartHeading>Grocery Orders</CartHeading>
            {groceryorder.length === 0 && (
              <EmptyCartItem item={`No grocery orders in cart!`} />
            )}
            {groceryorder.length > 0 && (
              <GroceryOrderTableContainer>
                <Table>
                  {groceryorder.length > 0 && (
                    <Tr>
                      <Th>Items</Th>
                      <Th>Store</Th>
                      <Th>Quantity</Th>
                    </Tr>
                  )}
                  {groceryorder && groceryorder.length ? (
                    groceryorder?.map((item) => {
                      return (
                        <Tr key={item?.id}>
                          <Td>{item?.item}</Td>
                          <Td>{item?.store}</Td>
                          <Td>{item?.quantity} </Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <EmptyCartItem item={`No grocery orders in cart!`} />
                  )}
                </Table>
                <ClearGroceryButton onClick={deleteGroceryOrder}>
                  Clear List
                </ClearGroceryButton>
              </GroceryOrderTableContainer>
            )}
          </Basket>

          {totalCartCost === 0 ? null : (
            <TotalContainer>
              <Total>Total: </Total>

              <TotalAmount> €{totalCartCost} </TotalAmount>
            </TotalContainer>
          )}

          <Button
            disabled={totalCartCost <= 0 ? true : false}
            onClick={pushToCheckout}
          >
            Proceed to Checkout
          </Button>
          <ClearCartButton onClick={clearCartState}>
            {" "}
            Clear Cart
          </ClearCartButton>
        </Wrapper>
      </Container>
    </>
  );
};

export default Cart;
