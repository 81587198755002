import React from "react";
import styled from "styled-components";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  line-height: 2;
`;
const Wrapper = styled.div``;

const TAC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <h1>
            Allgemeine Geschäftsbedingungen für V &amp; C Persönlicher Service
          </h1>

          <h2>Einführung</h2>

          <p>
            Diese auf dieser Webseite verfassten Allgemeinen
            Geschäftsbedingungen für die Website regeln Ihre Nutzung unserer
            Website, V&C Delivery, die unter www.vcdelivery.de zugänglich ist.
          </p>

          <p>
            Diese Bedingungen gelten vollständig und wirken sich auf Ihre
            Nutzung dieser Website aus. Durch die Nutzung dieser Website
            erklären Sie sich damit einverstanden, alle hierin niedergelegten
            Bedingungen zu akzeptieren. Sie dürfen diese Website nicht nutzen,
            wenn Sie mit diesen Allgemeinen Geschäftsbedingungen der Website
            nicht einverstanden sind. Diese Allgemeinen Geschäftsbedingungen
            wurden mithilfe des{" "}
            <a href="https://www.termsandcondiitionssample.com">
              Beispielgenerators für allgemeine Geschäftsbedingungen
            </a>{" "}
            erstellt.
          </p>

          <p>
            Minderjährige oder Personen unter 18 Jahren dürfen diese Website
            nicht nutzen.
          </p>

          <h2>Geistige Eigentumsrechte</h2>

          <p>
            Mit Ausnahme der Inhalte, die Sie besitzen, gemäß diesen
            Bedingungen, V &amp; C Personal Service und/oder seine Lizenzgeber
            besitzen alle geistigen Eigentumsrechte und Materialien, die auf
            dieser Website enthalten sind.
          </p>

          <p>
            Ihnen wird eine eingeschränkte Lizenz nur zum Anzeigen des auf
            dieser Website enthaltenen Materials gewährt.
          </p>

          <h2>Einschränkungen</h2>

          <p>Sie sind ausdrücklich auf Folgendes beschränkt:</p>

          <ul>
            <li>Veröffentlichen von Website-Material in anderen Medien;</li>
            <li>
              Verkauf, Unterlizenzierung und/oder anderweitige
              Kommerzialisierung von Website-Material;
            </li>
            <li>
              öffentliche Aufführung und/oder Darstellung von Website-Material;
            </li>
            <li>
              Nutzung dieser Website in irgendeiner Weise, die dieser Website
              schadet oder schaden könnte;
            </li>
            <li>
              Nutzung dieser Website in irgendeiner Weise, die den
              Benutzerzugriff auf diese Website beeinträchtigt;
            </li>
            <li>
              Die Nutzung dieser Website entgegen geltender Gesetze und
              Vorschriften oder in irgendeiner Weise kann der Website oder einer
              Person oder einem Unternehmen Schaden zufügen;
            </li>
            <li>
              Beteiligung an Data Mining, Datensammlung, Datenextraktion oder
              anderen ähnlichen Aktivitäten in Bezug auf diese Website;
            </li>
            <li>
              Nutzung dieser Website zur Durchführung von Werbung oder
              Marketing.
            </li>
          </ul>

          <p>
            Der Zugriff auf bestimmte Bereiche dieser Website ist für Sie und V
            &amp; C Personal Service kann Ihren Zugriff auf beliebige Bereiche
            dieser Website jederzeit nach eigenem Ermessen weiter einschränken.
            Alle Benutzer-IDs und Passwörter, die Sie möglicherweise für diese
            Website haben, sind vertraulich und Sie müssen die Vertraulichkeit
            ebenfalls wahren.
          </p>

          <h2>Ihre Inhalte</h2>

          <p>
            In diesen Allgemeinen Geschäftsbedingungen der Website bedeutet „Ihr
            Inhalt“ alle Audio-, Videotexte, Bilder oder andere Materialien, die
            Sie auf dieser Website anzeigen möchten. Indem Sie Ihre Inhalte
            anzeigen, gewähren Sie V &amp; C Personal Service eine nicht
            ausschließliche, weltweit unwiderrufliche, unterlizenzierbare Lizenz
            zur Nutzung, Vervielfältigung, Anpassung, Veröffentlichung,
            Übersetzung und Verbreitung in allen Medien.
          </p>

          <p>
            Ihre Inhalte müssen Ihre eigenen sein und dürfen nicht die Rechte
            Dritter verletzen. V &amp; C Personal Service behält sich das Recht
            vor, Ihre Inhalte jederzeit ohne Vorankündigung von dieser Website
            zu entfernen.
          </p>

          <h2>Ihre Privatsphäre</h2>

          <p>Bitte lesen Sie die Datenschutzerklärung.</p>

          <h2>Keine Garantien</h2>
          <p>
            Diese Website wird "wie besehen" mit allen Fehlern bereitgestellt,
            und V &amp; C Personal Service gibt keinerlei Zusicherungen oder
            Gewährleistungen jeglicher Art in Bezug auf diese Website oder die
            auf dieser Website enthaltenen Materialien ab. Außerdem darf nichts
            auf dieser Website als Beratung ausgelegt werden.
          </p>

          <h2>Haftungsbeschränkung</h2>

          <p>
            V &amp; C Personal Service, noch seine leitenden Angestellten,
            Direktoren und Mitarbeiter haften für alles, was sich aus Ihrer
            Nutzung dieser Website ergibt oder in irgendeiner Weise damit
            zusammenhängt, unabhängig davon, ob eine solche Haftung vertraglich
            vereinbart wurde. V &amp; C Personal Service, einschließlich seiner
            leitenden Angestellten, Direktoren und Mitarbeiter, haftet nicht für
            indirekte, Folge- oder besondere Haftungen, die sich aus Ihrer
            Nutzung dieser Website ergeben oder in irgendeiner Weise damit
            zusammenhängen.
          </p>

          <h2>Schadensersatz</h2>

          <p>
            Sie stellen V &amp; C Personal Service von und gegen jegliche
            und/oder alle Verbindlichkeiten, Kosten, Forderungen, Klagegründe,
            Schäden und Ausgaben, die in irgendeiner Weise im Zusammenhang mit
            Ihrer Verletzung einer der Bestimmungen dieser Bedingungen
            entstehen.
          </p>

          <h2>Salvatorische Klausel</h2>

          <p>
            Sollte sich eine Bestimmung dieser Bedingungen nach geltendem Recht
            als ungültig erweisen, werden diese Bestimmungen gelöscht, ohne dass
            dies die übrigen Bestimmungen hierin berührt.
          </p>

          <h2>Änderung der Begriffe</h2>

          <p>
            V &amp; C Personal Service ist berechtigt, diese Bedingungen
            jederzeit nach eigenem Ermessen zu überarbeiten, und durch die
            Nutzung dieser Website wird von Ihnen erwartet, dass Sie diese
            Bedingungen regelmäßig überprüfen.
          </p>

          <h2>Aufgabe</h2>

          <p>
            Das V&amp; C Personal Service ist berechtigt, seine Rechte und/oder
            Pflichten gemäß diesen Bedingungen ohne vorherige Ankündigung
            abzutreten, zu übertragen und weiterzuvergeben. Es ist Ihnen jedoch
            nicht gestattet, Ihre Rechte und/oder Pflichten aus diesen
            Bedingungen abzutreten, zu übertragen oder weiterzuvergeben.
          </p>

          <h2>Gesamte Vereinbarung</h2>

          <p>
            Diese Bedingungen stellen die gesamte Vereinbarung zwischen V &amp;
            C Personal Service und Ihnen in Bezug auf Ihre Nutzung dieser
            Website und ersetzen alle vorherigen Vereinbarungen und
            Übereinkünfte.
          </p>

          <h2>Geltendes Recht und Gerichtsstand</h2>

          <p>
            Diese Bedingungen unterliegen den Gesetzen des Bundesstaates de und
            werden in Übereinstimmung mit diesen ausgelegt, und Sie unterwerfen
            sich der nicht ausschließlichen Zuständigkeit der in de ansässigen
            Staats- und Bundesgerichte für die Beilegung von Streitigkeiten.
          </p>
        </Wrapper>
      </Container>
    </>
  );
};

export default TAC;
