import styled from "styled-components";
import { PastryData } from "../../../dummydata/data";
import { mobile, tablet } from "../../../responsive";
import FoodMenuItem from "./FoodMenuItem";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { publicRequest } from "../../../utils/requestMethod";
import { foodType } from "../../../utils/customHooks/types";
import Navbar from "../../../components/navbar/Navbar";

const Container = styled.div`
  /* height: 70vh; */
  /* padding-top: 5px; */
  align-items: center;
  display: flex;
  /* justify-content: center; */
  width: 100%;
  padding-bottom: 100px;
  flex-direction: column;
  ${mobile({
    width: "100vw",
    margin: "0px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  })}
  ${tablet({ padding: "0px", paddingBottom: "50px" })}
`;

const Title = styled.h1`
  color: #002035;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 100%;

  ${mobile({
    padding: "0px",
    flexDirection: "column",
    width: "95%",
  })}
  ${tablet({ padding: "0px", width: "100%" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  background-color: #002035;
  color: #deba59;
  ${mobile({ margin: "10px 0px" })}
  border-radius: 10px;
`;
const Option = styled.option`
  padding: 10px;
  margin: 10px;
`;

const FoodMenu = () => {
  const [restaurant, setRestaurant] = useState({});
  const [foods, setFoods] = useState([]);
  const [type, setType] = useState("");
  const [filteredFoods, setFilteredFoods] = useState([]);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [testFoods, setTestFood] = useState([]);

  useEffect(() => {
    const getRestaurant = async () => {
      const res = await publicRequest.get(`/restaurant/find/${id}`);
      setRestaurant(res.data);
    };
    getRestaurant();
  }, [id]);

  // useEffect(() => {
  //   const getFoods = async () => {
  //     const res = await publicRequest.get(`/food/find/restaurant/${id}`);
  //     setFoods(res.data);
  //   };
  //   getFoods();
  // }, [id]);

  useEffect(() => {
    const getFoods = async () => {
      const res = await publicRequest.get(`/food`);

      const testF = await res.data.filter((f) => {
        return f.restaurant === id;
      });
      setTestFood(testF);
    };
    getFoods();
  }, [id]);

  useEffect(() => {
    const setFoodTypes = async () => {
      const filteredfoods = testFoods.filter((item) => item.type === type);
      setFilteredFoods(filteredfoods);
    };
    setFoodTypes();
  }, [testFoods, type]);

  return (
    <>
      <Container>
        <Title> {restaurant.name} Food Menu</Title>
        <Select onChange={(e) => setType(e.target.value)}>
          <Option disabled selected>
            Select a Food Type
          </Option>

          {foodType.map((pt) => (
            <Option key={pt.id} value={pt.name}>
              {pt.name}
            </Option>
          ))}
        </Select>
        <Wrapper>
          {type
            ? filteredFoods.map((item) => (
                <FoodMenuItem item={item} key={item._id} />
              ))
            : testFoods.map((item) => (
                <FoodMenuItem item={item} key={item._id} />
              ))}
        </Wrapper>
      </Container>
    </>
  );
};

export default FoodMenu;
