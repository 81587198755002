//Working Code
import React from "react";
import Home from "./pages/Home";
import Navbar from "./components/navbar/Navbar";
import "./App.css";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import NewReservation from "./pages/user/reservation/NewReservation";
import NewFoodDelivery from "./pages/user/fooddelivery/NewFoodDelivery";
import Pastries from "./pages/user/pastry/Pastries";
import Footer from "./components/footer/Footer";
import NewGroceryOrder from "./pages/user/grocery/NewGroceryOrder";
import Laundries from "./pages/user/laundry/Laundries";
import Cart from "./pages/user/Cart";
import Checkout from "./pages/user/Checkout";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";
import Review from "./pages/user/Review";
import AboutUs from "./pages/static/About";
import ContactUs from "./pages/static/Contact";
import ErrorPage from "./pages/404";
import Team from "./pages/static/Team";
import TeamDetail from "./pages/static/TeamDetail";
import NFO from "./pages/user/foodorder/NFO";

//Third Party Imports
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReservationSucess from "./pages/user/reservation/ReservationSucess";
import PastryDetail from "./pages/user/pastry/PastryDetail";
import FoodMenuList from "./pages/user/foodorder/FoodMenuList";
import FoodMenuDetail from "./pages/user/foodorder/FoodMenuDetail";
import LaundryDetail from "./pages/user/laundry/LaundryDetail";
import PO from "./pages/user/pastry/PO";
import Success from "./pages/user/Success";
import Profile from "./pages/user/Profile";
import ForgotPassword from "./pages/auth/ForgotPassword";
import VerifyChangedPassword from "./pages/auth/VerifyChangedPassword";
import DiscountedPastries from "./pages/user/pastry/DiscountedPastries";
import Products from "./pages/user/product/Products";
import ProductDetail from "./pages/user/product/ProductDetail";
import GroceryProducts from "./pages/user/groceryproduct/GroceryProducts";
import GroceryProductDetail from "./pages/user/groceryproduct/GroceryProductDetail";
import Popup from "./components/cookie/Popup";
import TAC from "./pages/static/TAC";
import TACWebView from "./pages/webview/TACWebview";

const App = () => {
  const user = useSelector((state) => state?.user?.currentUser) || null;

  return (
    <>
      {/* <Navbar style={user ? { display: "block" } : { display: "none" }} /> */}
      <Popup />
      <Router>
        {/* {user && <Navbar />} */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="newgroceryorder"
            element={user ? <NewGroceryOrder /> : <Login />}
          />
          <Route
            path="reservierung"
            element={user ? <NewReservation /> : <Login />}
          />
          <Route
            path="reservationsucess"
            element={user ? <ReservationSucess /> : <Login />}
          />
          <Route
            path="waescheservice"
            element={user ? <Laundries /> : <Login />}
          />
          <Route path="waescheservice/:id" element={<LaundryDetail />} />
          <Route path="products" element={<Products />} />
          <Route
            path="lebensmittel-online-bestellen"
            element={<GroceryProducts />}
          />
          <Route path="product/:id" element={<ProductDetail />} />
          <Route
            path="lebensmittel-online-bestellen/:id"
            element={<GroceryProductDetail />}
          />

          <Route
            path="newfooddelivery"
            element={user ? <NewFoodDelivery /> : <Login />}
          />

          <Route path="essen-bestellen" element={<NFO />} />
          <Route path="essen-bestellen/:id" element={<FoodMenuList />} />
          <Route path="essen-bestellen/food/:id" element={<FoodMenuDetail />} />

          <Route path="pastries" element={<PO />} />
          <Route path="discountedpastries" element={<DiscountedPastries />} />
          <Route path="pastry/:id" element={<PastryDetail />} />
          <Route path="warenkorb" element={<Cart />} />
          <Route path="checkout" element={user ? <Checkout /> : <Login />} />
          <Route path="review" element={user ? <Review /> : <Login />} />
          <Route path="success" element={user ? <Success /> : <Login />} />

          {/* Auth Pages */}
          <Route
            path="login"
            element={user ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="register"
            element={user ? <Navigate to="/" /> : <Register />}
          />
          <Route path="profile" element={user ? <Profile /> : <Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route
            path="verifychangedpassword"
            element={<VerifyChangedPassword />}
          />

          {/* Static Pages */}
          <Route path="kontakt" element={user ? <ContactUs /> : <Login />} />
          <Route path="ueber-uns" element={user ? <AboutUs /> : <Login />} />
          <Route path="team" element={user ? <Team /> : <Login />} />
          <Route path="team/:id" element={user ? <TeamDetail /> : <Login />} />
          <Route path="impressum" element={<PrivacyPolicy />} />
          <Route path="tac" element={<TAC />} />
          <Route path="tac-webview" element={<TACWebView />} />

          {/* 404 Route */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
