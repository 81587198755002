import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    pastryorders: [],
    laundryorders: [],
    foodorders: [],
    fooddeliverys: [],
    groceryorders: [],
    productorders: [],
    groceryproducts: [],
    quantity: 0,
    shipping: {},
  },

  reducers: {
    //Pastry Reducers
    addPastry: (state, action) => {
      state.quantity += 1;
      state.pastryorders.push(action.payload);
    },
    removePastry: (state, action) => {
      state.pastryorders.splice(
        state.pastryorders.filter((id) => id === action.payload._id),
        1
      );
      state.quantity -= 1;
    },
    increasePastryQuantity: (state, action) => {
      state.pastryorders.forEach((pastry) => {
        if (pastry._id === action.payload._id) {
          pastry.quantity += 1;
        }
      });
    },
    reducePastryQuantity: (state, action) => {
      state.pastryorders.forEach((pastry) => {
        if (pastry._id === action.payload._id) {
          pastry.quantity -= 1;
        }
      });
    },

    // Product Reducers
    addProduct: (state, action) => {
      state.quantity += 1;
      state.productorders.push(action.payload);
    },
    removeProduct: (state, action) => {
      state.productorders.splice(
        state.productorders.filter((id) => id === action.payload._id),
        1
      );
      state.quantity -= 1;
    },
    increaseProductQuantity: (state, action) => {
      state.productorders.forEach((product) => {
        if (product._id === action.payload._id) {
          product.quantity += 1;
        }
      });
    },
    reduceProductQuantity: (state, action) => {
      state.productorders.forEach((product) => {
        if (product._id === action.payload._id) {
          product.quantity -= 1;
        }
      });
    },
    // Grocery Products Reducers
    addGroceryProduct: (state, action) => {
      state.quantity += 1;
      state.groceryproducts.push(action.payload);
    },
    removeGroceryProduct: (state, action) => {
      state.groceryproducts.splice(
        state.groceryproducts.filter((id) => id === action.payload._id),
        1
      );
      state.quantity -= 1;
    },
    increaseGroceryProductQuantity: (state, action) => {
      state.groceryproducts.forEach((product) => {
        if (product._id === action.payload._id) {
          product.quantity += 1;
        }
      });
    },
    reduceGroceryProductQuantity: (state, action) => {
      state.groceryproducts.forEach((product) => {
        if (product._id === action.payload._id) {
          product.quantity -= 1;
        }
      });
    },

    //Laundry Reducers
    addLaundry: (state, action) => {
      state.quantity += 1;
      state.laundryorders.push(action.payload);
    },
    removeLaundry: (state, action) => {
      state.laundryorders.splice(
        state.laundryorders.filter((id) => id === action.payload._id),
        1
      );
      state.quantity -= 1;
    },
    increaseLaundryQuantity: (state, action) => {
      state.laundryorders.forEach((laundry) => {
        if (laundry._id === action.payload._id) {
          laundry.quantity += 1;
        }
      });
    },
    reduceLaundryQuantity: (state, action) => {
      state.laundryorders.forEach((laundry) => {
        if (laundry._id === action.payload._id) {
          laundry.quantity -= 1;
        }
      });
    },

    //Foodorder Reducers
    addFoodorder: (state, action) => {
      state.quantity += 1;
      state.foodorders.push(action.payload);
    },
    removeFoodorder: (state, action) => {
      state.foodorders.splice(
        state.foodorders.filter((id) => id === action.payload._id),
        1
      );
      state.quantity -= 1;
    },
    increaseFoodorderQuantity: (state, action) => {
      state.foodorders.forEach((foodorder) => {
        if (foodorder._id === action.payload._id) {
          foodorder.quantity += 1;
        }
      });
    },
    reduceFoodorderQuantity: (state, action) => {
      state.foodorders.forEach((foodorder) => {
        if (foodorder._id === action.payload._id) {
          foodorder.quantity -= 1;
        }
      });
    },

    //Fooddelivery Reducers
    addFooddelivery: (state, action) => {
      state.quantity += 1;
      state.fooddeliverys.push(action.payload);
    },
    removeFooddelivery: (state, action) => {
      state.fooddeliverys.splice(
        state.fooddeliverys.filter((id) => id === action.payload._id),
        1
      );
      state.quantity -= 1;
    },

    //Groceryorder Reducers
    addGroceryorder: (state, action) => {
      state.quantity += 1;

      // state.groceryorders.push(action.payload);
      state.groceryorders = action.payload;
    },
    removeGroceryorder: (state, action) => {
      state.quantity -= 1;
      state.groceryorders = [];
    },

    // Extra State Properties
    clearCart: (state, action) => {
      state.quantity = 0;
      state.pastryorders = [];
      state.fooddeliverys = [];
      state.foodorders = [];
      state.groceryorders = [];
      state.laundryorders = [];
      state.productorders = [];
      state.groceryproducts = [];
      state.shipping = {};
    },
    updateShippingInfo: (state, action) => {
      state.shipping = { ...action.payload };
    },
  },
});

export const {
  addPastry,
  addFooddelivery,
  addFoodorder,
  addLaundry,
  addProduct,
  addGroceryorder,
  addGroceryProduct,
  removePastry,
  removeFooddelivery,
  removeFoodorder,
  removeGroceryorder,
  removeLaundry,
  removeProduct,
  removeGroceryProduct,
  increasePastryQuantity,
  increaseFooddeliveryQuantity,
  increaseFoodorderQuantity,
  increaseGroceryorderQuantity,
  increaseLaundryQuantity,
  increaseProductQuantity,
  increaseGroceryProductQuantity,
  reducePastryQuantity,
  reduceFooddeliveryQuantity,
  reduceLaundryQuantity,
  reduceGroceryorderQuantity,
  reduceFoodorderQuantity,
  reduceProductQuantity,
  reduceGroceryProductQuantity,
  clearCart,
  updateShippingInfo,
} = cartSlice.actions;
export default cartSlice.reducer;
