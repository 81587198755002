import React from "react";
import "./popup.css";
// Basic
// import CookieConsent from "react-cookie-consent";

// Option
import CookieConsent, { Cookies } from "react-cookie-consent";

const Popup = () => {
  return (
    <div className="popup">
      {/* Basic */}
      {/* <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}

      {/* option */}
      <CookieConsent
        disableStyles
        location="none"
        buttonText="Akzeptieren"
        cookieName="myAwesomeCookieName2"
        overlay
        overlayClasses="overlayclass"
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        // expires={150}
      >
        <h3>Cookie-Informationen</h3>
        Wir verwenden Cookies auf unseren Websites, um Ihre Erfahrung zu
        verbessern. Diese dienen der Optimierung unserer Website, der
        Weiterentwicklung von Services und Marketingzwecken. Der Einsatz
        bestimmter Cookies für die uneingeschränkte Nutzung unserer Website ist
        technisch notwendig. Indem Sie auf „Akzeptieren“ klicken, erklären Sie
        sich damit einverstanden, unsere Website mit dem Wissen zu nutzen, dass
        Cookies verfolgt und analysiert werden, um die Benutzererfahrung zu
        verbessern. Weitere Informationen finden Sie in unseren
        Datenschutzhinweisen.
      </CookieConsent>
    </div>
  );
};

export default Popup;
