import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobile, tablet } from "../../../responsive";
import { Delete, Edit } from "@mui/icons-material";
import { publicRequest } from "./../../../utils/requestMethod";
import { useDispatch, useSelector } from "react-redux";
import { addGroceryorder } from "../../../utils/redux/cartRedux";
import { useNavigate } from "react-router-dom";
// import { MenuItem, Select, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Navbar from "./../../../components/navbar/Navbar";

const Container = styled.div`
  padding-top: 20px;
  height: 100%;
  width: 100%;
  background-color: #deba59;
  color: #002035;
  padding-bottom: 30px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  ${mobile({ padding: "0px" })}
  ${mobile({ padding: "10px" })}
`;
const Title = styled.h1`
  text-align: center;
`;
const InnerWrapper = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column" })}
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${mobile({ padding: "5px" })}
`;

const LeftInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  ${mobile({ width: "100%" })}
`;
const LeftTitle = styled.h3`
  text-align: center;
`;

const LeftForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 5px;
  font-size: 20px;
`;
const Input = styled.input`
  height: 50px;
  font-size: 20px;
  margin: 5px;
  border-radius: 5px;
`;
const Select = styled.select`
  font-size: 20px;
  height: 50px;
  margin: 5px;
`;
const Option = styled.option``;
const LeftButton = styled.button`
  color: #deba59;
  background-color: #002035;
  height: 50px;
  border-radius: 25px;
  border: none;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  font-size: 20px;
`;

const Right = styled.div`
  flex: 2;
  ${mobile({ padding: "0px" })}
`;

const RightTitle = styled.h3`
  text-align: center;
  ${mobile({ marginBottom: "10px" })}
`;

const RightInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: center;
  ${mobile({ width: "100%" })}
`;

const RightTableContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Table = styled.table`
  width: 90%;
  /* border-collapse: collapse; */
  font-size: 1em;
  margin-bottom: 30px;
  padding: 5px;
`;
const Tr = styled.tr``;
const Th = styled.th`
  text-align: left;
  padding: 0.5em;
`;
const Td = styled.td`
  text-align: left;
  padding: 0.5em;
`;

const RightButton = styled.button`
  height: 50px;
  background-color: white;
  color: #002035;
  border: 1px solid #002035;
  border-radius: 25px;
  width: 50%;
  margin-bottom: 1.5em;
`;

const ActionContainer = styled.div`
  display: flex;
  ${mobile({ justifyContent: "space-around" })}
`;

const Disclaimer = styled.div`
  margin: 10px;
`;

const H4 = styled.h4``;

const NewGroceryOrder = () => {
  const [inputs, setInputs] = useState({});
  const [list, setList] = useState([]);
  const [stores, setStores] = useState([]);
  const [price, setPrice] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [store, setStore] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getStores = async () => {
      try {
        const res = await publicRequest.get(`/store`);
        setStores(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStores();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setInputs((prev) => {
      return { ...prev, [e?.target?.name]: e?.target?.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setList([{ ...inputs, id: new Date().toString() }, ...list]);
    console.log(list);
    setInputs({
      item: "",
      quantity: "",
      store: "",
    });
  };

  const onDelete = (id) => {
    console.log(id);
    setList(list.filter((item) => item.id !== id));
  };

  const placeOrder = () => {
    setQuantity(list.length);
    dispatch(addGroceryorder(list));
    console.log(list);
    setList([]);
    navigate("/warenkorb");
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>Lebensmittelbestellung</Title>
          <InnerWrapper>
            <Left>
              <LeftInnerWrapper>
                <LeftTitle>Einkaufsliste erstellen</LeftTitle>
                {errorMessage && (
                  <Stack sx={{ margin: "10px" }} spacing={2}>
                    <Alert
                      onClose={() => {
                        setErrorMessage("");
                      }}
                      severity="error"
                    >
                      {errorMessage}
                    </Alert>
                  </Stack>
                )}
                <LeftForm onSubmit={handleSubmit}>
                  <Label>Artikel eingeben</Label>
                  <Input
                    name="item"
                    value={inputs.item}
                    placeholder="E.g. Milk"
                    onChange={handleChange}
                    required
                    // onChange={(e) => setItem(e.target.value)}
                  />
                  <Label>Supermarkt</Label>
                  <Select name="store" onChange={handleChange} required>
                    <Option value="">Select a Store</Option>
                    {stores.map((s) => (
                      <Option key={s._id} value={s.name}>
                        {s.name}
                      </Option>
                    ))}
                  </Select>

                  <Label>Menge</Label>
                  <Input
                    name="quantity"
                    value={inputs.quantity}
                    default={1}
                    type="number"
                    onChange={handleChange}
                    required
                    // onChange={(e) => setQuantity(e.target.value)}
                  />
                  <LeftButton type="submit">Auf die Merkliste</LeftButton>
                </LeftForm>
              </LeftInnerWrapper>
            </Left>
            <Right>
              <RightInnerWrapper>
                <RightTitle>Einkaufsliste</RightTitle>
                <RightTableContainer>
                  <Table>
                    <Tr>
                      <Th>Artikel</Th>
                      <Th>Supermarkt </Th>
                      <Th>Menge</Th>
                      <Th>Aktionen</Th>
                    </Tr>

                    {list && list?.length
                      ? list.map((item) => {
                          return (
                            <Tr>
                              <Td>{item?.item}</Td>
                              <Td>{item?.store}</Td>
                              <Td>{item?.quantity} </Td>
                              <Td>
                                <ActionContainer
                                  onClick={() => onDelete(item?.id)}
                                >
                                  <Delete />
                                </ActionContainer>
                              </Td>
                            </Tr>
                          );
                        })
                      : null}
                  </Table>

                  <RightButton onClick={placeOrder}>
                    Lebensmittelbestellung machen
                  </RightButton>
                </RightTableContainer>
              </RightInnerWrapper>

              <Disclaimer>
                <H4>
                  Für den Lebensmitteleinkauf ist ein Pfand von 30 Euro zu
                  hinterlegen. 25 Cent pro Artikel werden als Servicegebühr
                  berechnet. Wo es ein Wechselgeld gibt, würde es bei der
                  Lieferung zurückgezahlt, geht der Einkauf über 30 Euro, müsste
                  der Restbetrag bei der Lieferung bezahlt werden.
                </H4>
              </Disclaimer>
            </Right>
          </InnerWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default NewGroceryOrder;
