import React from "react";
import styled from "styled-components";
import "../../components/style/team.css";
import { teams } from "../../dummydata/data";
import TeamCard from "../../components/team/TeamCard";
import { mobile, tablet } from "../../responsive";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  padding-top: 25px;
  padding-bottom: 100px;
  ${mobile({ paddingTop: "0px", paddingBottom: "100px" })}
  ${tablet({ paddingTop: "0px" })}
`;

const H2 = styled.h2`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex: wrap;
  margin-left: 50px;
  margin-right: 50px;
  ${mobile({
    flexDirection: "column",
    marginLeft: "10px",
    marginRight: "10px",
  })}
  ${tablet({
    flexDirection: "column",
    flex: "block",
  })}
`;

const Team = () => {
  return (
    <>
      <Navbar />
      <Container>
        <H2>OUR AMAZING TEAM</H2>
        <Wrapper>
          {teams.map((team) => (
            <TeamCard key={team.id} team={team} />
          ))}
        </Wrapper>
      </Container>
    </>
  );
};

export default Team;
