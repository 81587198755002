import styled from "styled-components";
import { mobile, tablet } from "../../../responsive";
import { useDispatch, useSelector } from "react-redux";
import { publicRequest, useUserRequest } from "../../../utils/requestMethod";
import { useNavigate } from "react-router";
import { useState } from "react";
import deliveryZoneCost from "../../../utils/customHooks/deliveryZones";
import { addFooddelivery } from "../../../utils/redux/cartRedux";
import Navbar from "../../../components/navbar/Navbar";

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #deba59 !important;
  color: #002035 !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({
    padding: "0px",
    width: "95%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "70%",
    margin: "0px",
  })}
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  ${mobile({
    margin: "5px",
  })}
`;
const Label = styled.label`
  flex: 1;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 300;
`;
const Input = styled.input`
  flex: 1;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;

const Select = styled.select`
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;
const Option = styled.option`
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
`;
const Button = styled.button`
  width: 100%;
  border: none;
  height: 40px;
  font-size: 20px;
  color: #deba59;
  background-color: #002035;
  border-radius: 20px;
  margin-top: 20px;
`;

const NewFoodDelivery = () => {
  const [inputs, setInputs] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(1);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handlePriceChange = (e) => {
    e.preventDefault();
    setPrice(e.target.value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInputs((prev) => {
      return { ...prev, [e?.target?.name]: e?.target?.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //Add to cart
    console.log(inputs);
    //Add Service Charge
    setQuantity(1);
    inputs.price = price;

    dispatch(addFooddelivery({ ...inputs, quantity }));
    // console.log({ ...inputs, quantity });
    navigate("/warenkorb");
  };

  //Add Payment Method

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>V&C Delivery</Title>
          <SubTitle>NEUE LEBENSMITTELLIEFERUNG</SubTitle>
          <Form onSubmit={handleSubmit}>
            <InputDiv>
              <Label>Name des Restaurants</Label>
              <Input
                name="restaurant"
                placeholder="E.g King's bite"
                onChange={handleChange}
                required
              />
            </InputDiv>
            <InputDiv>
              <Label>Adresse des Restaurants</Label>
              <Input
                name="restaurantAddress"
                placeholder="Down the street"
                onChange={handleChange}
                required
              />
            </InputDiv>

            <InputDiv>
              <Label>ID der Lebensmittelbestellung:</Label>
              <Input
                name="orderId"
                placeholder="E.g. ABCD12345LKJH"
                onChange={handleChange}
                required
              />
            </InputDiv>
            <InputDiv>
              <Label>Kosten der Essensbestellung:</Label>
              <Input
                name="price"
                placeholder="3"
                min={0}
                onChange={handlePriceChange}
                type="number"
                required
              />
            </InputDiv>

            <Button type="submit">Neuen Auftrag erstellen</Button>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default NewFoodDelivery;
