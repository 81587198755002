import React from "react";
import styled from "styled-components";
import Navbar from "../../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  line-height: 2;
`;
const Wrapper = styled.div``;

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <h1>Impressum</h1>
          <h2>Angaben gemaß 5 TMG </h2>

          <p>
            V & C Personal Service uG: Kaiserin Augusta alle 95, 10589 Berlin{" "}
          </p>

          <p>Buro: Kaiserin Augusta alle 95, 10589 Berlin</p>

          <p>Handelsregisternummer: HRB 225407</p>

          <p>Ust-ID-Nr: DE34145720 </p>

          <p>Vertreten Durch: Vanessa Obatare Omote </p>

          <h2>Kontakt</h2>
          <p>Telefon: +4915171673185 </p>

          <br />

          <p>Telefon: 03040552126 </p>

          <br />
          <p>Email: info@vcdelivery.de </p>

          <h1>
            Datenschutzrichtlinie für V &amp; C Persönliche
            Dienstleistungserbringung
          </h1>

          <p>
            Bei V&C Delivery, erreichbar über https://vcdelivery.de/, einer
            unserer wichtigsten Prioritäten ist die Privatsphäre unserer
            Besucher. Diese Datenschutzrichtlinie Dokument enthält Arten von
            Informationen, die gesammelt und aufgezeichnet werden von V&C
            Delivery und wie wir es verwenden.
          </p>

          <p>
            Wenn Sie weitere Fragen haben oder weitere Informationen zu unserem
            benötigen Datenschutzerklärung, zögern Sie nicht, uns zu
            kontaktieren.
          </p>

          <p>
            Diese Datenschutzerklärung gilt nur für unsere Online-Aktivitäten
            und ist gültig für Besucher unserer Website in Bezug auf die
            Informationen, die sie geteilt und/oder in V&C Delivery gesammelt.
            Diese Richtlinie gilt für keine Informationen, die offline oder über
            andere Kanäle als diese Website gesammelt wurden. Unsere
            Datenschutzrichtlinie wurde mit Hilfe des{" "}
            <a href="https://www.privacypolicygenerator.info/">
              Kostenloser Generator für Datenschutzrichtlinien
            </a>
            .
          </p>

          <h2>Zustimmung</h2>

          <p>
            Durch die Nutzung unserer Website stimmen Sie hiermit unserer
            Datenschutzrichtlinie zu und stimme seinen Bedingungen zu.
          </p>

          <h2>Von uns gesammelte Informationen</h2>

          <p>
            Die personenbezogenen Daten, um deren Angabe Sie gebeten werden, und
            die Gründe, warum Sie darum gebeten werden, werden Ihnen unter
            deutlich gemacht der Punkt, an dem wir Sie bitten, Ihre persönlichen
            Daten anzugeben.
          </p>
          <p>
            Wenn Sie uns direkt kontaktieren, erhalten wir möglicherweise
            zusätzliche Informationen über Sie wie Name, E-Mail-Adresse,
            Telefonnummer, Inhalt der Nachricht und/oder der Anhänge, die Sie
            uns senden, und alle anderen Informationen, die Sie angeben können.
          </p>
          <p>
            Wenn Sie sich für ein Konto registrieren, fragen wir möglicherweise
            nach Ihrem Kontakt Informationen, einschließlich Angaben wie Name,
            Firmenname, Adresse, E-Mail-Adresse und Telefonnummer.
          </p>

          <h2>Wie wir Ihre Informationen verwenden</h2>

          <p>
            Wir verwenden die von uns gesammelten Informationen auf verschiedene
            Weise, unter anderem für:
          </p>

          <ul>
            <li>Bereitstellung, Betrieb und Pflege unserer Website</li>
            <li>
              Verbesserung, Personalisierung und Erweiterung unserer Website
            </li>
            <li>Verstehen und Analysieren, wie Sie unsere Website nutzen</li>
            <li>Neue Produkte, Dienste, Features und Funktionen entwickeln</li>
            <li>
              Kommunizieren Sie mit Ihnen, entweder direkt oder über einen
              unserer Partner, einschließlich für den Kundenservice, Ihnen zur
              Verfügung zu stellen Updates und andere Informationen in Bezug auf
              die Website und für Marketing- und Werbezwecke
            </li>
            <li>Ihnen E-Mails senden</li>
            <li>Betrug erkennen und verhindern</li>
          </ul>

          <h2>Protokolldateien</h2>

          <p>
            V&C Delivery folgt einem Standardverfahren zur Verwendung von
            Protokolldateien. Diese Dateien protokollieren Besucher, wenn sie
            Websites besuchen. Alle Hosting-Unternehmen tun dies und a Teil der
            Analyse von Hosting-Diensten. Die von log Dateien umfassen Internet
            Protocol (IP)-Adressen, Browsertyp, Internet Dienstanbieter (ISP),
            Datums- und Zeitstempel, Verweis-/Ausgangsseiten und möglicherweise
            die Anzahl der Klicks. Diese sind mit keinerlei Informationen
            verknüpft das ist persönlich identifizierbar. Der Zweck der
            Informationen ist z Analyse von Trends, Verwaltung der Website,
            Verfolgung der Benutzerbewegungen der Website und das Sammeln
            demografischer Informationen.
          </p>

          <h2>Datenschutzrichtlinien der Werbepartner</h2>

          <p>
            Sie können diese Liste konsultieren, um die Datenschutzrichtlinie
            für jeden der zu finden Werbepartner von V&C Delivery.
          </p>

          <p>
            Ad-Server oder Ad-Netzwerke von Drittanbietern verwenden
            Technologien wie Cookies, JavaScript oder Web Beacons, die jeweils
            verwendet werden Werbung und Links, die auf V&C Delivery erscheinen,
            die direkt gesendet werden zum Browser der Benutzer. Sie erhalten
            dabei automatisch Ihre IP-Adresse das kommt vor. Diese Technologien
            werden verwendet, um die Wirksamkeit zu messen ihrer Werbekampagnen
            und/oder um die Werbung zu personalisieren Inhalte, die Sie auf
            Websites sehen, die Sie besuchen.
          </p>

          <p>
            Beachten Sie, dass V&C Delivery keinen Zugriff auf oder keine
            Kontrolle über diese Cookies hat von Drittwerbetreibenden verwendet.
          </p>

          <h2>Datenschutzrichtlinien von Drittanbietern</h2>

          <p>
            Die Datenschutzrichtlinie von V&C Delivery gilt nicht für andere
            Werbetreibende oder Websites. Daher empfehlen wir Ihnen, die
            jeweiligen Datenschutzrichtlinien zu konsultieren dieser Ad-Server
            von Drittanbietern, um detailliertere Informationen zu erhalten. Es
            kann enthalten ihre Praktiken und Anweisungen zum Opt-out bestimmte
            Optionen.{" "}
          </p>

          <p>
            Sie können Cookies über Ihren individuellen Browser deaktivieren
            Optionen. Um detailliertere Informationen über die Cookie-Verwaltung
            zu erhalten Bei bestimmten Webbrowsern finden Sie es unter den
            Browsern jeweiligen Webseiten.
          </p>

          <h2>
            CCPA-Datenschutzrechte (Meine personenbezogenen Daten nicht
            verkaufen)
          </h2>

          <p>
            Unter dem CCPA haben kalifornische Verbraucher neben anderen Rechten
            die Recht auf:
          </p>
          <p>
            Fordern Sie an, dass ein Unternehmen, das die personenbezogenen
            Daten eines Verbrauchers sammelt Offenlegung der Kategorien und
            spezifischen personenbezogenen Daten, die a Unternehmen über
            Verbraucher gesammelt hat.
          </p>
          <p>
            Fordern Sie ein Unternehmen auf, alle personenbezogenen Daten des
            Verbrauchers zu löschen die ein Unternehmen gesammelt hat.
          </p>
          <p>
            Verlangen Sie, dass ein Unternehmen, das die personenbezogenen Daten
            eines Verbrauchers verkauft, dies nicht tut die personenbezogenen
            Daten des Verbrauchers verkaufen.
          </p>
          <p>
            Wenn Sie eine Anfrage stellen, haben wir einen Monat Zeit, um Ihnen
            zu antworten. Wenn du eines dieser Rechte ausüben möchten,
            kontaktieren Sie uns bitte.
          </p>

          <h2>DSGVO-Datenschutzrechte</h2>

          <p>
            Wir möchten sicherstellen, dass Sie alle Ihre Daten vollständig
            kennen Schutzrechte. Jeder Benutzer hat Anspruch auf:
          </p>
          <p>
            Das Recht auf Zugang – Sie haben das Recht, Kopien Ihrer Daten
            anzufordern persönliche Daten. Für diesen Service können wir Ihnen
            eine kleine Gebühr berechnen.
          </p>
          <p>
            Das Recht auf Berichtigung – Sie haben das Recht, von uns zu
            verlangen Korrigieren Sie alle Informationen, die Sie für unrichtig
            halten. Sie haben auch die Recht zu verlangen, dass wir die
            Informationen vervollständigen, die Sie für richtig halten
            unvollständig.
          </p>
          <p>
            Das Recht auf Löschung – Sie haben das Recht zu verlangen, dass wir
            löschen Ihre personenbezogenen Daten unter bestimmten Bedingungen.
          </p>
          <p>
            Das Recht auf Einschränkung der Verarbeitung – Sie haben das Recht,
            dies zu verlangen Wir schränken die Verarbeitung Ihrer
            personenbezogenen Daten unter bestimmten Umständen ein Bedingungen.
          </p>
          <p>
            Das Recht auf Widerspruch gegen die Verarbeitung – Sie haben das
            Recht, Widerspruch einzulegen unsere Verarbeitung Ihrer
            personenbezogenen Daten unter bestimmten Bedingungen.
          </p>
          <p>
            Das Recht auf Datenübertragbarkeit – Sie haben das Recht, von uns zu
            verlangen die von uns gesammelten Daten an eine andere Organisation
            zu übertragen, oder unter bestimmten Bedingungen direkt an Sie.
          </p>
          <p>
            Wenn Sie eine Anfrage stellen, haben wir einen Monat Zeit, um Ihnen
            zu antworten. Wenn du eines dieser Rechte ausüben möchten,
            kontaktieren Sie uns bitte.
          </p>

          <h2>Informationen für Kinder</h2>

          <p>
            Ein weiterer Teil unserer Priorität ist das Hinzufügen von Schutz
            für Kinder während das Internet benutzen. Wir ermutigen Eltern und
            Erziehungsberechtigte zu beachten, an ihren Online-Aktivitäten
            teilnehmen und/oder sie überwachen und leiten.
          </p>

          <p>
            V&C Delivery sammelt wissentlich keine personenbezogenen Daten von
            Kindern unter 13 Jahren. Wenn Sie glauben, dass Ihr Kind
            Bereitstellung dieser Art von Informationen auf unserer Website, wir
            stark ermutigen Sie, sich umgehend mit uns in Verbindung zu setzen,
            und wir werden unser Bestes tun Bemühungen, solche Informationen
            unverzüglich aus unseren Aufzeichnungen zu entfernen.
          </p>
        </Wrapper>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
