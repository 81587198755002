import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px 0px;
  position: relative;
  top: 0;
  left: 0;
`;
const Circle = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #fff6dc;
  position: absolute;
`;
const IconSet = styled.div`
  z-index: 2;
`;
const Text = styled.div`
  margin-bottom: 10px;
`;

const EmptyCartItem = ({ item }) => {
  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <Circle />
          <IconSet>
            <Icon icon="emojione-v1:shopping-bags" width="54" height="54" />
          </IconSet>
        </InnerWrapper>
        <Text>{item}</Text>
      </Wrapper>
    </Container>
  );
};

export default EmptyCartItem;
