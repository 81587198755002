import styled from "styled-components";
import Navbar from "../../../components/navbar/Navbar";
import Pastries from "./Pastries";

const Container = styled.div`
  background-color: rgb(222, 186, 89);
  padding-bottom: 70px;
  width: 100vw;
`;

const PO = () => {
  document.title = "Süße Leckereien direkt von v&cdelivery";

  document
    .querySelector("meta[name='description']")
    .setAttribute(
      "content",
      "Bestellen Sie Ihre Lieblingsgebäcke und Puddings online bei v&cdelivery und lassen Sie sie direkt an Ihre Tür liefern. Wählen Sie aus einer Vielzahl von Süßigkeiten und Lieferoptionen."
    );

  return (
    <>
      <Navbar />
      <Container>
        <Pastries />
      </Container>
    </>
  );
};

export default PO;
