import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { mobile, tablet } from "../../responsive";
import { publicRequest } from "./../../utils/requestMethod";
import { useNavigate } from "react-router";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Container = styled.div`
  background-color: #deba59;
  color: #002035;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  ${mobile({
    padding: "0px",
    width: "95%",
    margin: "0px",
  })}
  ${tablet({
    padding: "0px",
    width: "75%",
    margin: "0px",
  })}
`;

const Input = styled.input`
  height: 5vh;
  margin: 5px;
  font-size: 20px;
`;

const Label = styled.label``;
const Button = styled.button`
  height: 4vh;
  margin: 5px;
  border: none;
  border-radius: 2vh;
  background-color: #002035;
  color: #deba59;
  cursor: pointer;
`;

const VerifyChangedPassword = () => {
  const [inputs, setInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    setInputs((prev) => {
      return { ...prev, [e?.target?.name]: e?.target?.value };
    });
  };

  const handleSubmit = async () => {
    try {
      const res = await publicRequest
        .post("/users/verifychangedpassword", inputs)
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          setErrorMessage(error);
        });
    } catch (error) {}
  };
  return (
    <Container>
      <Wrapper>
        <Label>Enter Email</Label>
        {errorMessage && (
          <Stack sx={{ margin: "10px" }} spacing={2}>
            <Alert
              onClose={() => {
                setErrorMessage("");
              }}
              severity="error"
            >
              {errorMessage}
            </Alert>
          </Stack>
        )}
        <Input
          type="email"
          name="email"
          placeholder="example@email.com"
          required
          onChange={handleChange}
        />
        <Label>Enter Password Token</Label>
        <Input
          type="email"
          placeholder="123456"
          name="passwordResetToken"
          required
          onChange={handleChange}
        />
        <Label>Enter New Password</Label>
        <Input name="password" type="text" onChange={handleChange} required />
        <Button onClick={handleSubmit}>Send</Button>
      </Wrapper>
    </Container>
  );
};

export default VerifyChangedPassword;
