export const HomeData = [
  {
    id: 1,
    url: "reservierung",
    title: "Reservierungen",
    image:
      "https://images.unsplash.com/photo-1585694854987-19b609e7721b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    desc: "Sie können in jedem Restaurant das mit uns zusammen arbeiten ganz einfach einen Tisch zum Mittag- oder Abendessen reservieren. Sie können Reservierungen für Ihre Geschäftstreffen, Geburtstagsfeiern, Hochzeitsjubiläen oder andere gesellschaftliche Veranstaltungen vornehme",
    cta: "Jetzt reservieren",
  },
  {
    id: 2,
    url: "products",
    title: "Besonderes Einkaufen Lieferservice",
    image:
      "https://images.unsplash.com/photo-1607083206203-821b1f96c7f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGRpc2NvdW50JTIwc2hvcHBpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    desc: "Kaufen Sie bei uns für Ihren Urlaub zu einem ermäßigten Preis ein",
    cta: "Kaufe jetzt",
  },
  {
    id: 3,
    url: "lebensmittel-online-bestellen",
    title: "Online Supermarket Lieferservice",
    image:
      "https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z3JvY2VyeXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1000&q=60",
    desc: "Für den Lebensmitteleinkauf ist ein Pfand von 30 Euro zu hinterlegen. 25 Cent pro Artikel werden als Servicegebühr berechnet. Wo es ein Wechselgeld gibt, würde es bei der Lieferung zurückgezahlt, geht der Einkauf über 30 Euro, müsste der Restbetrag bei der Lieferung bezahlt werden.",
    cta: "Jetzt einkaufen",
  },
  {
    id: 4,
    url: "waescheservice",
    title: "Wäscherei Lieferservice",
    image:
      "https://images.unsplash.com/photo-1635274605638-d44babc08a4f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGxhdW5kcnl8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    desc: "Wir holen die Wäsche bei Ihnen zu Hause ab und bringen sie in die Wäscherei und wieder zurück.Sie können Ihre Wäsche verfolgen und Preise verschiedener Betriebe vergleichen.",
    cta: "Jetzt bestellen",
  },
  {
    id: 5,
    url: "pastries",
    title: "Gebäck/Pudding Lieferservice",
    image:
      "https://images.unsplash.com/photo-1483695028939-5bb13f8648b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cGFzdHJpZXN8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    desc: "Sie lieben frisches Bio-Brot? Sie finden eine große Auswahl an frischem Bio-Brot und Kuchen, die Sie bei uns bestellen können und wir liefern es Ihnen vor dem Frühstück nach Hause. Sie können auch wöchentliche Lieferungen mit uns planen. Es ist auch wichtig, verschiedenes wie Chiapudding, Sandwiches, Obst und Brot für Ihr Frühstück hervorzuheben. Alle diese Dienstleistungen werden von Personen ausgeführt und wir fungieren als Lieferanten Unternehmen",
    cta: "Jetzt bestellen",
  },
  {
    id: 6,
    url: "newfooddelivery",
    title: "Lebensmittellieferservice",
    image:
      "https://plus.unsplash.com/premium_photo-1661726637770-fefe253c10e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8Zm9vZCUyMGRlbGl2ZXJ5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
    desc: "Sie haben bereits Essen in einem Restaurant bestellt und bezahlt und möchten nur, dass wir es vor Ihrer Haustür abholen und abgeben? Kein Problem! Sie können einen Fahrer buchen und uns online bezahlen.",
    cta: "Jetzt bestellen",
  },
  {
    id: 7,
    url: "essen-bestellen",
    title: "Essensbestellung Lieferservice",
    image:
      "https://images.unsplash.com/photo-1555939594-58d7cb561ad1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1000&q=60",
    desc: "Sie möchten Essen bei unserem Partner Restaurants bestellen oder vom Restaurant Ihrer Wahl? Kein Problem! Sie können Ihr gewünschtes Essen bestellen und bei uns online bezahlen",
    cta: "Jetzt bestellen",
  },
  // {
  //   id: 8,
  //   url: "newgroceryorder",
  //   title: "Erstellen Sie eine Einkaufsliste",
  //   image:
  //     "https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z3JvY2VyeXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1000&q=60",
  //   desc: "Brauchen Sie Lebensmittel? Sie können Ihre Lebensmitteleinkäufe bei uns erledigen, indem Sie Ihre Anfrage in der App mit einer Liste der benötigten Artikel platzieren, und wir erledigen Ihre Einkäufe und liefern Lebensmittel zu Ihnen nach Hause. Pro Artikel berechnen wir 25 Cent.",
  //   cta: "Jetzt einkaufen",
  // },
];

export const PastryData = [
  {
    id: 1,
    name: "Velvet Cake",
    image:
      "https://images.unsplash.com/photo-1517427294546-5aa121f68e8a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dmVsdmV0JTIwY2FrZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Cart",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 2,
    name: "Croissant",
    image:
      "https://images.unsplash.com/photo-1530610476181-d83430b64dcd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y3JvaXNzYW50fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Cart",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 3,
    name: "Baguette",
    image:
      "https://images.unsplash.com/photo-1604322796450-ec2c51485442?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YmFndWV0dGV8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Cart",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 4,
    name: "Wheat Bread",
    image:
      "https://images.unsplash.com/photo-1549931319-a545dcf3bc73?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8d2hlYXQlMjBicmVhZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Cart",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 5,
    name: "Egg Sandwich",
    image:
      "https://images.unsplash.com/photo-1608847569619-b5602f65ffa0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZWdnJTIwc2FuZHdpY2h8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Cart",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 6,
    name: "Chicken Sandwich",
    image:
      "https://images.unsplash.com/photo-1604467707321-70d5ac45adda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Y2hpY2tlbiUyMHNhbmR3aWNofGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Cart",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
];

export const LaundryData = [
  {
    id: 1,
    name: "Men's Trouser",
    image:
      "https://images.unsplash.com/photo-1624378439575-d8705ad7ae80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dHJvdXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Laundry Order",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 2,
    name: "Men's Jacket",
    image:
      "https://images.unsplash.com/photo-1551028719-00167b16eac5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8amFja2V0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
    price: 157,
    cta: "Add to Laundry Order",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 3,
    name: "Men's Shirt",
    image:
      "https://images.pexels.com/photos/297933/pexels-photo-297933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    price: 157,
    cta: "Add to Laundry Order",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 4,
    name: "Women's Shirt",
    image:
      "https://images.pexels.com/photos/297933/pexels-photo-297933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    price: 157,
    cta: "Add to Laundry Order",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 5,
    name: "Women's Jacket",
    image:
      "https://images.pexels.com/photos/54206/pexels-photo-54206.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    price: 157,
    cta: "Add to Laundry Order",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 6,
    name: "Women's Trouser",
    image:
      "https://images.pexels.com/photos/1082529/pexels-photo-1082529.jpeg?auto=compress&cs=tinysrgb&w=600",
    price: 157,
    cta: "Add to Laundry Order",
    desc: "Lorem Ipsum dolor sit amet consectetur adipisicing elit.",
  },
];

export const teams = [
  {
    id: 1,
    img: "https://cdn.vectorstock.com/i/preview-1x/77/37/default-avatar-profile-icon-grey-photo-vector-17317737.webp",
    rank: "Managing Director/Geschäftsführer",
    name: "Vanessa Omote",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bio2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bio3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 2,
    img: "https://cdn.vectorstock.com/i/preview-1x/77/37/default-avatar-profile-icon-grey-photo-vector-17317737.webp",
    rank: "Lead Designer/Führender Designer",
    name: "Ariadna Koesling",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bio2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bio3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 3,
    img: "https://cdn.vectorstock.com/i/preview-1x/77/37/default-avatar-profile-icon-grey-photo-vector-17317737.webp",
    rank: "Lead Marketer/Führender Vermarkter",
    name: "Bilhya Joshi",
    bio1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bio2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bio3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];

export const RestaurantData = [
  {
    id: 1,
    title: "KFC",
    image:
      "https://images.unsplash.com/photo-1585694854987-19b609e7721b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 2,
    title: "MacDonalds",
    image:
      "https://images.unsplash.com/photo-1555939594-58d7cb561ad1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1000&q=60",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 3,
    title: "Mr Biggs",
    image:
      "https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z3JvY2VyeXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1000&q=60",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 4,
    title: "SFC",
    image:
      "https://images.unsplash.com/photo-1635274605638-d44babc08a4f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGxhdW5kcnl8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 5,
    title: "NetCafe",
    image:
      "https://images.unsplash.com/photo-1617347454431-f49d7ff5c3b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Zm9vZCUyMGRlbGl2ZXJ5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 6,
    title: "Mama Putt",
    image:
      "https://plus.unsplash.com/premium_photo-1661726637770-fefe253c10e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8Zm9vZCUyMGRlbGl2ZXJ5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
];
