const deliveryZoneCost = [
  {
    id: 1,
    area: "Zone A",
    price: 0,
    db: { area: "Zone A", price: 0 },
  },
  {
    id: 2,
    area: "Zone B",
    price: 0,
    db: { area: "Zone B", price: 0 },
  },
  {
    id: 3,
    area: "Zone C",
    price: 0,
    db: { area: "Zone C", price: 0 },
  },
];
export default deliveryZoneCost;
