import styled from "styled-components";
import Homecard from "../components/homecard/Homecard";
import Navbar from "../components/navbar/Navbar";

const Container = styled.div`
  background-color: #deba59;
  padding-bottom: 20px;
`;

const Home = () => {
  document.title = "V&C Delivery";

  document
    .querySelector("meta[name='description']")
    .setAttribute(
      "content",
      "V&C Delivery ist ein umfassendes Lieferunternehmen mit einer breiten Palette an Services, darunter Online-Einkauf, Lebensmittel-Lieferung, Essensbestellung und Wäscherei-Services. Unser 360-Grad-Ansatz sorgt dafür, dass wir all Ihre Lieferbedürfnisse erfüllen können, was es Ihnen einfach und bequem macht, die Dinge zu bekommen, die Sie brauchen. Vertrauen Sie V&C Delivery für schnelle und zuverlässige Lieferung und hervorragenden Kundenservice."
    );
  return (
    <>
      <Navbar />
      <Container>
        <Homecard />
      </Container>
    </>
  );
};

export default Home;
