export const pastryType = [
  {
    id: 1,
    name: "Kuchen",
  },
  {
    id: 2,
    name: "Brot",
  },
  {
    id: 3,
    name: "Pudding",
  },
  {
    id: 4,
    name: "Brotchen",
  },
  {
    id: 5,
    name: "Sandwich",
  },
];

export const laundryType = [
  {
    id: 1,
    name: "Herren",
  },
  {
    id: 2,
    name: "Damen",
  },
  {
    id: 3,
    name: "Kinder",
  },
  {
    id: 4,
    name: "Sports",
  },
  {
    id: 5,
    name: "Kostüm",
  },
  {
    id: 6,
    name: "Unisex",
  },
];

export const foodType = [
  {
    id: 1,
    name: "Suppen",
  },
  {
    id: 2,
    name: "Salete & Vorspeisen",
  },
  {
    id: 3,
    name: "Vegatarisch",
  },
  {
    id: 4,
    name: "Alles Essen",
  },
  {
    id: 5,
    name: "Getrank",
  },
];

export const productType = [
  {
    id: 1,
    name: "Cloth",
  },
  {
    id: 2,
    name: "Pastry",
  },
];
